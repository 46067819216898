/**
 * binder에 함수 연결부분을 옮김
 */

import {
  setStickyHeight,
  setToggle,
  setPopup,
  setPageType,
  setIsToolbar,
  setStickyOld,
  setCurw,
  setMySubs,
  setWindowOld,
} from '@/_modules/common/common.global';
import { getJoongangRefProperty } from '@paidPages/common/utils';
import { calculateAppMenuHeight, setBodyScrollForIos } from '@/_modules/common/helper/utils';

import { anchorAdverLayoutObserver, isEnableAnchorAdverRef } from '@/_modules/common/helper/advertise';
import {
  addBottomPosForLoginProposal,
  moveBottomPosForLoginProposal,
  addBottomPosForhalfPopup,
  moveHalfPopupBottomPos,
  addTopLayerPosPopup,
  moveTopLayerPos,
} from '@/_modules/common/helper/moveFixedBottomLayer';
import sendVideoStatusForAndroid from '@/_modules/common/helper/sendVideoStatForAndroid';

const { $ } = window;

/**
 * ovp 및 youtube 플레이어 메세지를 안드로이드로 전달
 */
sendVideoStatusForAndroid();

/**
 * 드롭다운 토글
 * 연관페이지: https://stg-www.joongang.co.kr/search/news?keyword=%ED%95%9C%EA%B5%AD&sort=score&js=localmodule
 */
export function toggleDropdown() {
  $('.dropdown_toggle:not(.ignore)')
    .off()
    .on('click', function () {
      if ($(this).parent('.dropdown').hasClass('open')) {
        closeToggle();
      } else {
        window.toggle.push($(this).parent('.dropdown'));
        $(this).parent('.dropdown').toggleClass('open');
      }
    });
  // 드롭다운 아이템 클릭 시 모든 상위 element 에 active 클래스가 추가되므로 추후 수정 필요
  $('.dropdown_item a')
    .off()
    .on('click', function () {
      $(this).parents().siblings().removeClass('active');
      $(this).parents().addClass('active');
      closeToggle();
    });
}

/**
 * 로그인
 * 연관페이지: https://stg-www.joongang.co.kr/article/25097869?js=localmodule#home
 * @param {string} url
 */
export function jaLogin(url) {
  url = url || window.LOGIN_URL;
  location.href = url;
}

/**
 * 회원가입
 * 연관페이지: https://stg-www.joongang.co.kr/article/25097869?js=localmodule#home
 */
export function jaJoin() {
  location.href = window.JOIN_URL;
}

/**
 * 로그아웃
 * 연관페이지: https://stg-www.joongang.co.kr/article/25097869?js=localmodule#home
 */
export function jaLogout() {
  window.utils.member.removeRefreshToken();
  if (typeof window.subs !== 'undefined') {
    window.subs && window.subs.clearSubsUserData();
  }
  location.href = window.LOGOUT_URL;
}

/**
 * 계정 정보
 * 연관페이지: https://stg-www.joongang.co.kr/article/25097869?js=localmodule#home
 */
export function jaMyInfo() {
  window.utils.member.removeRefreshToken();
  location.href = window.MY_INFO_URL;
}

export function jaMyPage() {
  // window.utils.member.removeRefreshToken();
  location.href = window.MY_PAGE_URL;
}

/**
 * swiper scrollbar 이용 시 아이템 개수에 따른 scrollbar 노출 여부(공통사항)
 * 연관페이지: https://stg-www.joongang.co.kr/parenting?js=localmodule(추천페키지)
 */
export function swiperScollYn() {
  const pageType = window.utils.device.getPageType();

  if (pageType === 'mobile' || pageType === 'tablet') {
    $('.swiper-container').each(function () {
      const $this = $(this);
      const $scrollBar = $this.siblings('.swiper-scrollbar');

      if ($scrollBar.length) {
        const $items = $this.find('.swiper-slide');

        if ($items.length < 10) {
          $scrollBar.addClass('hide');
        } else {
          $scrollBar.removeClass('hide');
        }
      }
    });
  }
}

/**
 * 검색레이어에서 텝키 눌렀을때 포커스 위치 커스터마이즈 코드
 * 연관페이지: https://stg-www.joongang.co.kr/search/news?keyword=%ED%95%9C%EA%B5%AD&sort=score&js=localmodule
 * @param {object} obj
 * @param {object} evt event
 */
export function trapTabKey(obj, evt) {
  /* layerpopup focus out prevent */
  const focusableElementsString =
    'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable], video';

  // if tab or shift-tab pressed
  if (evt.which === 9) {
    // get list of all children elements in given object
    const o = obj.find('*');
    // get list of focusable items
    const focusableItems = o.filter(focusableElementsString).filter(':visible');
    // get currently focused item
    const focusedItem = $(':focus');
    // get the number of focusable items
    const numberOfFocusableItems = focusableItems.length;
    // get the index of the currently focused item
    const focusedItemIndex = focusableItems.index(focusedItem);

    if (evt.shiftKey) {
      // back tab
      // if focused on first item and user preses back-tab, go to the last focusable item
      if (focusedItemIndex === 0) {
        focusableItems.get(numberOfFocusableItems - 1).focus();
        evt.preventDefault();
      }
    } else if (focusedItemIndex === numberOfFocusableItems - 1) {
      // forward tab
      // if focused on the last item and user preses tab, go to the first focusable item
      focusableItems.get(0).focus();
      evt.preventDefault();
    }
  }
}

/**
 * #header, #sticky 제어
 * 연관페이지: https://stg-www.joongang.co.kr/search/news?keyword=%ED%95%9C%EA%B5%AD&sort=score&js=localmodule
 */
export function setSticky() {
  const $sticky = $('#sticky');
  const $header = $('#header');
  let $main = $('main');

  if ($main.length < 1) {
    $main = $('section.contents');
  }

  const change = function (event) {
    event = event || {};
    const pageType = window.utils.device.getPageType();
    const rect = $sticky[0].getBoundingClientRect();

    // this.stickyHeight = this.stickyHeight || 0;
    setStickyHeight(window.stickyHeight || 0);

    if (event.type === 'resize' && window.curw !== window.utils.getElementSize(window).width) {
      $sticky.removeClass('sticky_top');
      $sticky.removeAttr('style');
      $main.removeAttr('style');
      // window.curw = window.utils.getElementSize(window).width;
      setCurw(window.utils.getElementSize(window).width);
    }

    if (pageType === 'mobile' || pageType === 'tablet') {
      // 모바일에서만 스크롤을 위로 올릴 때 header 노출
      if (window.stickyOld < window.utils.getScrollY()) {
        $header.addClass('position_ab');
        $sticky.css('top', '0px');
      } else if (window.stickyOld >= window.utils.getScrollY() + window.STICKY_DELAY) {
        $header.removeClass('position_ab');
        $sticky.css('top', ($sticky.hasClass('sticky_top') ? $header.outerHeight() : '0') + 'px');
      }
    } else {
      $header.removeClass('position_ab');
      $sticky.removeAttr('style');
    }

    if (!$sticky.hasClass('sticky_top') && rect.bottom < 0) {
      if (
        pageType === 'mobile' || pageType === 'tablet'
          ? !$sticky.hasClass('m_unsticky') // 모바일 -> .m_unsticky 체크
          : !$sticky.hasClass('p_floating') // pc -> .p_floating 체크
      ) {
        // window.stickyHeight = $sticky.outerHeight();
        setStickyHeight($sticky.outerHeight(true));
        $main.css('margin-top', window.stickyHeight + 'px');
        $sticky.addClass('sticky_top');
      }
    } else if (
      ($sticky.hasClass('sticky_top') && window.stickyHeight > window.utils.getScrollY()) ||
      window.utils.getScrollY() === 0
    ) {
      $sticky.removeClass('sticky_top');
      $sticky.removeAttr('style');
      $main.css('marginTop', '');
    }

    setStickyOld(window.utils.getScrollY());
    stickyCallback({ manStickyElement: $sticky[0] });
  };

  if ($sticky.length > 0) {
    change({});
    // this.curw = window.utils.getElementSize(window).width;
    setCurw(window.utils.getElementSize(window).width);
    document.addEventListener('scroll', change);
    window.addEventListener('resize', change);
  }

  if ($('#plus_top_banner').length > 0 && $('#plus_top_banner').is(':visible')) {
    // 상단에 플러스 배너 붙은 경우 position_ab 클래스 추가
    $header.addClass('position_ab');
  }
}

/**
 * sticky의 change 발생시 ref에 등록된 callback을 실행시킨다
 */
function stickyCallback({ manStickyElement }) {
  const stickyCalllbacks = window?.joongang?.shareRef?.stickyCalllbacks;

  if (stickyCalllbacks) {
    stickyCalllbacks.forEach(callback => {
      callback(manStickyElement);
    });
  }
}

/**
 * top의 전체 사이트멥 메뉴 팝업
 * 연관페이지: https://stg-www.joongang.co.kr/search/news?keyword=%ED%95%9C%EA%B5%AD&sort=score&js=localmodule
 * @param {any} self 본인
 * @param {any} ele ele
 */

export function openLayer(self, obj) {
  autoCloseLayer();
  let $obj;

  if (obj.constructor.name === 'HTMLDivElement') {
    $obj = $(obj);
  } else if (obj instanceof window.jQuery) {
    $obj = obj;
  } else {
    $obj = self.find(obj);
  }

  if ($obj.length > 1) {
    let filtered = $obj.filter(function (_idx, element) {
      return (
        $(element).parents('.swiper-slide-duplicate:not(.swiper-slide-duplicate-active, .swiper-slide-active)')
          .length === 0
      );
    });
    if (filtered.length > 1) {
      filtered = filtered.filter(function (_idx, element) {
        return $(element).parents('.swiper-slide-active').length > 0;
      });
    }
    $obj = $(filtered[0]);
  }

  if ($obj.hasClass('menu_popup')) {
    if ($obj.hasClass('show')) {
      closeLayer($('.menu_popup .btn_close'));
      closeToggle();
    } else {
      if (window.utils.getWindowSize().width < window.utils.config.breakpointLarge) {
        if (window.utils.device.joongangApp && $(window).scrollTop() === 0 && $obj.hasClass('full_popup')) {
          $('html, body').animate({ scrollTop: 1 }, 0);
        }

        $('.sticky_menu').addClass('pop_hide');
        $('.plotting_banner, .plotting_popup, .drawer').addClass('pop_hide');
        $('.header_right_area').addClass('pop_hide');
        $('.sticky_top').not("[id='header']").addClass('pop_hide');
        $('body, html').addClass('overflow_hidden');
        setDisableBodyScroll();
        $('.ab_player.sticky').addClass('pop_hide');
        $('#player_wrapper').addClass('pop_hide');
        if ($('#header').hasClass('black_type')) {
          $('#header').addClass('position_fi');
        }
        // 앵커 광고영역 있을때는 광고에서 자동으로 붙여주는 body 하단 패딩 유지
        if (isEnableAnchorAdverRef.current) {
          const tmpBodyPadding = document.body.style.padding;
          $('html, body').removeAttr('style');
          document.body.style.padding = tmpBodyPadding;
        } else {
          $('html, body').removeAttr('style');
        }
      } else {
        $('.scroll_indicator').addClass('pop_hide');
      }

      $obj.removeClass('hide');
      setTimeout(function () {
        $obj.addClass('show');
      }, 1);
      $(obj).attr('tabindex', '0').focus();
    }
  } else {
    window.popup.push($obj);
    // 안드로이드 앱 pull to refresh 이슈로 인해 추가
    if (window.utils.device.joongangApp && $(window).scrollTop() === 0 && $obj.hasClass('full_popup')) {
      $('html, body').animate({ scrollTop: 1 }, 0);
    }

    if ($obj.hasClass('layer_share') && window.utils.device.joongangApp) {
      // 2021.09.23 ios 요청으로 앱 공유 시 이미지 넘기지 않도록 함
      // var shareImage = window.share.IMAGE ? window.share.IMAGE : $("meta[property='og:image']").attr("content");
      window.utils.app.share(document.location.href, window.share.TITLE /* shareImage*/);
    } else if ($obj.hasClass('show')) {
      $obj.removeClass('show').addClass('hide');
      $('.pop_hide').removeClass('pop_hide');
    } else {
      $obj.removeClass('hide');
      setTimeout(function () {
        $obj.addClass('show');
      }, 1);
      $(obj).attr('tabindex', '0').focus();

      if ($obj.hasClass('full_popup')) {
        closeLayer($('.menu_popup .btn_close'));

        // 모바일이거나 중앙플러스가 아닌 경우만 html 태그에 overflow_hidden 클래스 붙여주기.
        const isMobile = ['tablet', 'mobile'].includes(window.pageType);
        const isPlus = location.pathname.indexOf('/plus') >= 0;
        if (isMobile || !isPlus) {
          $('html').addClass('overflow_hidden');
        }
        $('body').addClass('overflow_hidden');

        setDisableBodyScroll();
        $('.plotting_banner, .plotting_popup, .drawer').addClass('pop_hide');
        $('.ab_player.sticky').addClass('pop_hide');
        $('#player_wrapper').addClass('pop_hide');
        if ($obj.hasClass('search_popup')) {
          $('.sticky_top').not("[id='header']").addClass('pop_hide');
          $('#header.sticky_top .header_wrap').addClass('pop_hide');
          $('.article_header .headline').addClass('pop_hide');
          $('.header_right_area, .masthead_menu, .sticky_menu').addClass('pop_hide');
        }
      }
    }
  }
}

/**
 * top의 전체 사이트멥 메뉴 팝업의 너비에 따른 레이아웃 표시방식 변경
 * 연관페이지: https://stg-www.joongang.co.kr/search/news?keyword=%ED%95%9C%EA%B5%AD&sort=score&js=localmodule
 */
export function resizeMenuPopup() {
  if ($('.menu_popup').hasClass('show')) {
    if (window.utils.getWindowSize().width < window.utils.config.breakpointLarge) {
      $('.sticky_top').not("[id='header']").addClass('pop_hide');
      $('.sticky_menu').addClass('pop_hide');
      $('.plotting_banner, .plotting_popup, .drawer').addClass('pop_hide');
      $('.header_right_area').addClass('pop_hide');
      $('body, html').addClass('overflow_hidden');
      setDisableBodyScroll();
      $('.scroll_indicator').removeClass('pop_hide');
      $('#player_wrapper').addClass('pop_hide');
      if ($('#header').hasClass('black_type')) {
        $('#header').addClass('position_fi');
      }
    } else {
      $('.sticky_top').not("[id='header']").removeClass('pop_hide');
      $('.scroll_indicator').addClass('pop_hide');
      $('.sticky_top, .sticky_menu').removeClass('pop_hide');
      $('.header_right_area').removeClass('pop_hide');
      $('body, html').removeClass('overflow_hidden');
      setEnableBodyScroll();
      $('.plotting_banner, .plotting_popup, .drawer').removeClass('pop_hide');
      $('#player_wrapper').removeClass('pop_hide');
      if ($('#header').hasClass('black_type')) {
        $('#header').removeClass('position_fi');
      }
    }
  }
}

/*
 * 레이어가 열리거나 스크롤 동작 안하도록 처리
 * 연관페이지: https://stg-www.joongang.co.kr/search/news?keyword=%ED%95%9C%EA%B5%AD&sort=score&js=localmodule
 */
export function setDisableBodyScroll() {
  if (typeof window.bodyScrollLock !== 'undefined') {
    $('html').css('height', '100vh');
    setTimeout(function () {
      const { disableBodyScroll, enableBodyScroll } = window.bodyScrollLock;
      disableBodyScroll($('body')[0]);
      enableBodyScroll($('body')[0]);
      if (window.utils.getWindowSize().width < window.utils.config.breakpointLarge) {
        $('#header').attr('style', 'position:fixed !important');
      }
    }, 10);
  }
}

/*
 * 레이어가 닫힐때 스크롤 동작 하도록 처리
 * 연관페이지: https://stg-www.joongang.co.kr/search/news?keyword=%ED%95%9C%EA%B5%AD&sort=score&js=localmodule
 */
export function setEnableBodyScroll() {
  if (typeof window.bodyScrollLock !== 'undefined') {
    $('html')[0].style.removeProperty('height');
    $('html').removeAttr('style');
    if ($('#header').length) {
      $('#header')[0].style.removeProperty('position');
    }
    window.bodyScrollLock.clearAllBodyScrollLocks();
  }
}

/**
 * 전체 스크롤 스탑
 * (플러스 공통 wall 영역에서 wall이 나타날 경우 호출)
 * 연관페이지: https://stg-backoffice.joongang.co.kr/template/1307
 */
export function scrollStop() {
  $('body, html').addClass('overflow_hidden');
  setDisableBodyScroll();
}

/**
 * 전체 스크롤 스타트
 * (검색 상세옵션의 레이어팝업 닫을때 사용)
 * 연관페이지: https://stg-www.joongang.co.kr/search?keyword=%ED%95%9C%EA%B5%AD&js=localmodule
 */
export function scrollStart() {
  $('body, html').removeClass('overflow_hidden');
  setEnableBodyScroll();
}

/**
 * 로그인후 top에서 로그인 아이디 클릭하면 나오는 토글메뉴
 * 연관페이지: https://stg-www.joongang.co.kr/search/news?keyword=%ED%95%9C%EA%B5%AD&js=localmodule
 * @param {element} ele ele
 */
export function toggleOpen(ele) {
  const $ele = $(ele);
  const $parent = $ele.parent();

  if ($ele.attr('id') === 'login') {
    if ($('#menu_popup').hasClass('show')) {
      closeLayer($('.menu_popup .btn_close'));
    }
  }

  if ($parent.hasClass('open')) {
    closeToggle();
  } else {
    window.toggle.push($parent);
    $parent.toggleClass('open');
  }
}

/**
 * 로그인후 top에서 로그인 아이디 클릭하면 나오는 토글메뉴에서 토클 닫을때
 * 연관페이지: https://stg-www.joongang.co.kr/search/news?keyword=%ED%95%9C%EA%B5%AD&js=localmodule
 */
export function closeToggle() {
  $.each(window.toggle, function (_index, $item) {
    if ($item !== null) {
      $item.removeClass('open');
      // window.toggle.pop(); // 루프에서 루프 객체의 아이템을 삭제하면 오류!!!
    }
  });

  // toggle = []; // 모든 레이어 닫은 후, toggle 배열 초기화
  // window 참조 변경은 global 모듈 함수를 통해서만 변경 가능하도록 수정
  setToggle([]);
}

/**
 * background click시 layer popup 닫기
 * (로그인후 top에서 로그인 아이디 클릭하면 나오는 토글메뉴에서 body 영역 클릭해서 닫을때)
 * 연관페이지: https://stg-www.joongang.co.kr/search/news?keyword=%ED%95%9C%EA%B5%AD&js=localmodule
 */
export function autoCloseLayer() {
  if (window.popup.length) {
    const $item = window.popup[window.popup.length - 1];

    if (!$item.hasClass('full_popup')) {
      $item.find('.btn_close').click();
    } else if ($item.hasClass('menu_popup')) {
      $item.find('.btn_close').click();
    }
  }

  if ($('.plotting_banner .plotting_popup.active').length) {
    $('.plotting_banner .plotting_popup').fadeOut(800, function () {
      $('.plotting_banner .plotting_popup').removeClass('active');
      $('.plotting_banner .plotting_popup').removeAttr('style');
    });
  }
  closeToggle();
}

/**
 * 상단 로그인 아이디, 검색 결과 정렬 옵션 등 토글레이어가 닫힐때
 * 테스트: https://stg-www.joongang.co.kr/search/news?keyword=%ED%95%9C%EA%B5%AD&js=localmodule
 * @param {*} btnCk
 */
export function closeLayer(btnCk) {
  closeToggle();
  setBodyScrollForIos('add');
  const $btnCk = $(btnCk);

  if ($btnCk.closest('.layer_popup.show, .full_popup.show').length) {
    const $popup = $btnCk.closest('.layer_popup.show, .full_popup.show');
    if ($popup.hasClass('full_popup')) {
      // 메뉴팝업일때 예외처리
      if ($popup.hasClass('menu_popup')) {
        $popup.removeAttr('style').removeClass('show');
        if ($('#header').hasClass('black_type')) {
          $('#header').removeClass('position_fi');
        }
      } else {
        $popup.removeClass('show').addClass('hide');
      }

      // 화면에 상단 플러스 배너 있는 경우, 위치 설정.
      const pageType = window.utils.device.getPageType();
      if (pageType === 'mobile' || pageType === 'tablet') {
        // 모바일에서만 스크롤을 위로 올릴 때 header 노출
        if ($('#plus_top_banner').length > 0 && $('#plus_top_banner').is(':visible')) {
          const $header = $('#header');

          // eslint-disable-next-line no-console
          console.log('closeLayer - scroll value', window.utils.getScrollY());
          const PLUS_STICKY_BANNER_HEIGHT = 60;

          if (PLUS_STICKY_BANNER_HEIGHT > window.utils.getScrollY()) {
            $header.addClass('position_ab');
            $header.css('top', PLUS_STICKY_BANNER_HEIGHT + 'px');
          } else {
            // if (this.stickyOld >= utils.getScrollY() + STICKY_DELAY) {
            $header.removeClass('position_ab');
            $header.css('top', '0px');
          }
        }
      }

      $('.pop_hide').removeClass('pop_hide');
      $popup.find('.layer_popup.show, .open').removeClass('show').addClass('hide');

      if ($popup.find('.layer_popup.show').length) {
        $popup.find('.layer_popup.show').removeClass('show').addClass('hide');
      }
      // 동작하지 않는 이상한 코드 주석처리 2023/2/17 추후 삭제예정
      /*
      else if ($popup.find('.layer_popup.show').length) {
        $popup.find('.open').removeClass('open');
      }
      */

      // window.popup = [];
      // window 참조 변경은 global 모듈 함수를 통해서만 변경 가능하도록 수정
      setPopup([]);

      if ($('#wrapper .ad_video.fixed_top').length > 0) {
        const adheight = $('#wrapper .ad_video.fixed_top').height();
        $('#header').css('top', adheight + 'px');
      }
    } else {
      $popup.removeClass('show').addClass('hide');
      window.popup.pop();
    }
  }
  $btnCk.closest('.layer_print.show').removeClass('show').addClass('hide');
  $btnCk.parents('.layer_ticker').addClass('hide');
  $btnCk.parents('.half_popup.show').removeClass('show').addClass('hide');
  $btnCk.parents('.layer_brand').removeClass('open');

  if (!$('.full_popup.show').length) {
    $('html, body').removeClass('overflow_hidden');
    setEnableBodyScroll();
  }
}

/**
 * SNS 공유 (클릭 이벤트)
 * 연관페이지: https://stg-www.joongang.co.kr/article/25098131?js=localmodule
 * @param {string} selector 선택자
 * @param {string} layerSelector 레이어 선택자
 */
export function shareSns(selector, layerSelector) {
  selector = selector || '#layer_share a[data-sns-type], #letter_archive_share [data-sns-type]';
  const $layer = layerSelector ? $(layerSelector) : $(selector).parents('.layer_share');

  $(selector).click(function (e) {
    e.preventDefault();

    const snsType = $(this).data('sns-type');
    const cid = $(this).data('cid') || null;
    const ctype = $(this).data('ctype') || null;
    const url = $(this).data('url') || null;
    const chnl = $(this).data('chnl') || null; // 채널seq
    const epsd = $(this).data('epsd') || null; // 에피소드seq
    const options = { cid, ctype, url, chnl, epsd };
    const katalkimg = window.KATALK_IMG === '' ? window.utils.getMetaValue('og:image') : window.KATALK_IMG;
    const utmCampaign = $(this).data('utm-campaign') || null;
    const utmSourceMapping = {
      ktalk: 'kakao',
      face: 'facebook',
      twt: 'twitter',
      copy: '',
    };

    if (utmCampaign !== null) {
      const utmText =
        '?utm_source=' +
        utmSourceMapping[snsType] +
        '&utm_medium=social&utm_campaign=' +
        utmCampaign +
        '&utm_term=&utm_content=';
      options.url = window.utils.getMetaValue('og:url') + utmText;
    }

    switch (snsType) {
      case 'ktalk':
        if (window.JPOD_TYPE === 'V' || window?.share?.useOgInfoForKakao) {
          window.utils.share.kakaotalk({
            title: window.utils.getMetaValue('og:title'),
            description: window.utils.getMetaValue('og:description'),
            imageUrl: katalkimg,
            link: window.utils.getMetaValue('og:url'),
          });
        } else {
          window.utils.share.kakaotalk(options);
        }
        break;
      case 'face':
        window.utils.share.facebook(options);
        break;
      case 'twt':
        window.utils.share.twitter(options);
        break;
      case 'mail':
        window.utils.share.mail(options);
        break;
      case 'band':
        window.utils.share.naverband(options);
        break;
      case 'copy':
        window.utils.share.copy(selector + '[data-sns-type="copy"]', options);
        $layer.find('.btn_close').trigger('click');
        break;
      default:
        console.warn('snsType is wrong');
    }
  });
}

/**
 * 기사 리스트 날짜 선택 달력
 */
function articleListDatepicker() {
  const $listDatepicker = $('.date_wrap2 input');
  if ($listDatepicker.length > 0) {
    const $listDatepickerBtn = $('.date_wrap2 button');
    const query = window.utils.queryStringToObject();
    const selectDate = query.endDate && query.endDate !== '' ? query.endDate.replace(/-/g, '.') : '';
    const todayDate = $.datepicker.formatDate('yy.mm.dd', new Date());
    $listDatepicker.datepicker({
      maxDate: 0,
      minDate: new Date(1965, 9 - 1, 22),
      onSelect() {
        $(this).change();
      },
    });
    $listDatepicker.datepicker('setDate', selectDate.length > 0 ? selectDate : 'today');
    $listDatepicker.change(function () {
      const query = window.utils.queryStringToObject();
      // eslint-disable-next-line eqeqeq
      if ($(this).val() != todayDate) {
        query.endDate = $(this).val().replace(/\./g, '-');
      } else {
        delete query.endDate;
      }
      if (query.page) {
        delete query.page;
      }
      location.href = location.pathname + window.utils.objectToQueryString(query);
    });
    if ($listDatepickerBtn.length > 0) {
      $listDatepickerBtn.click(function () {
        $listDatepicker.datepicker('show');
      });
      // eslint-disable-next-line eqeqeq
      if (selectDate.length > 0 && selectDate != todayDate) {
        $listDatepickerBtn.addClass('on');
      }
    }
  }
}

/**
 * 구독버튼 클릭
 * 연관페이지: https://stg-www.joongang.co.kr/article/25098683?js=localmodule#home
 * @param {selector} target ele selector
 * @param {object} callback
 */
export function subscribeBtnClick(target, success, fail) {
  if (window.subs) {
    window.subs.applySubscribe(target, success, fail);
  }
}

/**
 * 본문 내 구독버튼 클릭
 * Todo: 테스트 페이지 찾기
 * @param {selector} target ele selector
 */
export function artSubsBtnClick(target) {
  subscribeBtnClick(
    target,
    function () {
      const $letterBox = $(target).closest('.letter_wrap');
      if ($letterBox.length > 0) {
        if ($(target).hasClass('active')) {
          $letterBox.find('.subs_before').addClass('hide');
          $letterBox.find('.subs_after').removeClass('hide');
        } else {
          $letterBox.find('.subs_before').removeClass('hide');
          $letterBox.find('.subs_after').addClass('hide');
        }
      }
    },
    function (result) {
      if (
        result._ERR_CODE &&
        !window.utils.empty(result._ERR_CODE._DATA) &&
        result._ERR_CODE._DATA !== window.SUBS_ERROR_CODE.AGREE
      ) {
        const $letterBox = $(target).closest('.letter_wrap');
        if ($letterBox.length > 0) {
          $letterBox.find('.subs_before').addClass('hide');
          $letterBox.find('.subs_after').removeClass('hide');
          $letterBox.find('.subs_after .description').html(result._MESSAGE._DATA);
        }
      }
    }
  );
}

/**
 * 업데이트 시간에 따라 UP 표기 (selector에 data-upt-dt 필수)
 * 연관페이지: https://stg-www.joongang.co.kr/jpod?js=localmodule
 * @param {string} selector #issue_list [data-upd-dt]
 * @param {number} hour UP표기 기준 시간 (기본 : 24시간)
 */
export function showIcoUp(selector, hour) {
  if (!selector) {
    return;
  }

  const icoTmpl = $('#ico_up_tmpl');
  if (icoTmpl.length < 1) {
    return;
  }
  hour = hour || 24;

  $(selector).each(function () {
    const between = window.utils.format.dateBetween($(this).data('upd-dt'));

    if (between !== '') {
      const gap = window.utils.format.dateBetween($(this).data('upd-dt')) / 1000 / 60 / 60;
      if (gap <= hour) {
        const $ico = icoTmpl.tmpl();
        $(this).replaceWith($ico);
        $ico[0].setAttribute('data-format-initialized', 'Y');
      } else {
        $(this).remove();
      }
    }
  });
}

/**
 * 등록 시간에 따라 New 표기 (selector에 data-reg-dt 필수)
 * 연관페이지: https://stg-www.joongang.co.kr/jpod?js=localmodule
 * @param {string} selector #issue_list [data-reg-dt]
 * @param {number} hour NEW표기 기준 시간 (기본 : 24시간)
 */
export function showIcoNew(selector, hour) {
  if (!selector) {
    return;
  }

  const icoTmpl = $('#ico_new_tmpl');
  if (icoTmpl.length < 1) {
    return;
  }

  hour = hour || 24;

  $(selector).each(function () {
    const between = window.utils.format.dateBetween($(this).data('reg-dt'));
    if (between !== '') {
      const gap = window.utils.format.dateBetween($(this).data('reg-dt')) / 1000 / 60 / 60;
      if (gap <= hour) {
        const $ico = icoTmpl.tmpl();
        $(this).replaceWith($ico);
        $ico[0].setAttribute('data-format-initialized', 'Y');
      } else {
        $(this).remove();
      }
    }
  });
}

/**
 * 내 구독상품 목록 조회
 * Todo 현재 사용부분 없어보임
 * @param {selector} target ele selector
 */
export function getMySubsList(callback) {
  window.utils.ajax.get({
    url: window.utils.config.apiPath + 'subscribe.my',
    data: { scope: 'A' },
    withCredentials: true,
    success(result) {
      // window.mySubs = result._DATA;
      setMySubs(result._DATA);
      setActiveMySubs();
      if (typeof callback !== 'undefined') {
        callback(result);
      }
    },
  });
}

/**
 * 구독여부 표시
 * 연관페이지: https://stg-www.joongang.co.kr/article/25098683?js=localmodule#home
 * 연관페이지2: https://stg-www.joongang.co.kr/myfeed?js=localmodule
 * 연관페이지3: https://stg-www.joongang.co.kr/plus?js=localmodule
 * @param {object} target ele selector
 */
export function setActiveMySubs() {
  // 연재와 JPOD 의 경우 뉴스레터 페이지를 제외한 나머지에서는 기존에 사용하던 구독중 텍스트가 노출되기 때문에 /newsletter 로 분기 처리함 (JST24-795)
  const isNewsletter = location.pathname.indexOf('/newsletter') >= 0;

  // $('[data-subsid]').text(!isPlus ? '구독' : '레터 받기');
  const subscribeButton = document.querySelectorAll('[data-subsid]');
  subscribeButton.forEach(sbBtn => {
    if (sbBtn.getAttribute('data-channeltype') !== 'LETTER') {
      sbBtn.textContent = '구독';
    } else {
      if (!sbBtn.classList.contains('btn_news_letter')) {
        sbBtn.classList.add('btn_news_letter');
      }
      sbBtn.textContent = '레터 받기';
    }
  });
  $('[data-subsid]').removeClass('active');
  $.each(window.mySubs, function (index, item) {
    const $btn = $(
      '[data-channelid=' +
        item.CHANNEL_ID +
        '][data-channeltype=' +
        item.CHANNEL_TYPE +
        ']' +
        ',[data-subsid=' +
        item.MAIN_SCB_NO +
        ']' +
        ',[data-subsid=' +
        item.SCB_NO +
        ']'
    );
    if ($btn.length > 0) {
      if (
        (isNewsletter && (item.CHANNEL_TYPE === 'PKG' || item.CHANNEL_TYPE === 'JPOD')) ||
        item.CHANNEL_TYPE === 'LETTER'
      ) {
        $btn.text('레터 받는 중');
        if (!$btn.hasClass('btn_news_letter')) {
          $btn.addClass('btn_news_letter');
        }
      } else {
        $btn.text('구독중');
      }
      $btn.addClass('active');
      $btn.each(function () {
        if (!$btn.hasClass('btn_plus_news_letter')) {
          delete this.dataset.evntCtg;
        }
        let evntActType = null;
        switch (item.CHANNEL_TYPE) {
          case 'LETTER':
            evntActType = 'newsletter';
            break;
          case 'REPORTER':
            evntActType = 'journalist';
            break;
          case 'PKG':
            if (item.PKG_DIV === 'S') {
              evntActType = 'series';
            } else if (item.PKG_DIV === 'I') {
              evntActType = 'issue';
            }
            break;
          case 'JPOT':
            evntActType = 'podcast';
            break;
          default:
            evntActType = '';
        }
        if (evntActType !== '') {
          $.extend(this.dataset, { evntAct: 'click: ' + evntActType + ' unsubscribe' });
        }
      });
    }
  });

  // 플러스 콘텐트에 대한 추가 처리 (뉴스레터 구독)
  setJoongangPlusActiveMySubs();

  // 기자 구독 수 0일 때 구독중인 경우 제어
  if ($('#scb_cnt').text() === '0' && $('.journalist_popularity .btn_subscribe').hasClass('active')) {
    $('#scb_cnt').text(1);
    $('#scb_cnt').attr('data-cnt', 1);
  }

  // 아티클 기사 구독 기능 예외처리 (전체 구독)
  const $btnSubscribe = $('.btn_subscribes');
  if ($btnSubscribe !== undefined && $btnSubscribe.length > 0) {
    $.each($btnSubscribe, function (index, item) {
      const subSids = item.dataset.subsid || '';
      const arrSubSids = subSids.split(',');
      const scnCnt = arrSubSids.length;
      let myScnCnt = 0;
      if (scnCnt > 1) {
        for (const i in arrSubSids) {
          // eslint-disable-next-line no-loop-func
          $.each(window.mySubs, function (index, item) {
            if (String(arrSubSids[i]) === String(item.MAIN_SCB_NO)) {
              myScnCnt += 1;

              return false;
            }
            return true;
          });
        }
      }
      if (scnCnt === myScnCnt) {
        $btnSubscribe.text(item.dataset.channeltype === 'REPORTER' ? '구독중' : '레터 받는 중');
        $btnSubscribe.addClass('active');

        if (!$(item).hasClass('btn_plus_news_letter')) {
          delete item.dataset.evntCtg;
        }

        const evntActType = 'journalist';
        if (evntActType !== '') {
          $.extend(item.dataset, { evntAct: 'click: ' + evntActType + ' unsubscribe' });
        }
        $('.btn.btn_black.btn_ok').text('네');
      }
    });
  }
}

/**
 * 구독여부 표시 (중앙일보 유료화 - 플러스)
 * 연관페이지: https://stg-www.joongang.co.kr/plus?js=localmodule
 * @param {object} target ele selector
 */
export function setJoongangPlusActiveMySubs() {
  $('.btn_plus_news_letter').text('레터 받기');
  $('.btn_plus_news_letter').removeClass('active');

  $.each(window.mySubs, function (_index, item) {
    const $btn = $(
      '.btn_plus_news_letter[data-channelid=' +
        item.CHANNEL_ID +
        '][data-channeltype=' +
        item.CHANNEL_TYPE +
        ']' +
        ',.btn_plus_news_letter[data-subsid=' +
        item.MAIN_SCB_NO +
        ']' +
        ',.btn_plus_news_letter[data-subsid=' +
        item.SCB_NO +
        ']'
    );

    if ($btn.length > 0) {
      $btn.text('레터 받는 중');
      $btn.addClass('active');
      $btn.each(function () {
        if (!$btn.hasClass('btn_plus_news_letter')) {
          delete this.dataset.evntCtg;
        }
        let evntActType = null;
        switch (item.CHANNEL_TYPE) {
          case 'LETTER':
            evntActType = 'newsletter';
            break;
          default:
            evntActType = '';
        }
        if (evntActType !== '') {
          $.extend(this.dataset, { evntAct: 'click: ' + evntActType + ' unsubscribe' });
        }
      });
    }
  });
}

/**
 * 관심여부 표시 (중앙일보 유료화 - 플러스)
 * @param {object} target ele selector
 */
export function setJoongangPlusActiveMyFavorites() {
  const $button = $('.btn_myfavo:button');

  $button.each(function () {
    const fid = $(this).data('fid');
    const ftype = $(this).data('ftype');

    // eslint-disable-next-line eqeqeq
    if (window.myFavoList[ftype].includes(fid.toString())) {
      $(this).addClass('active');
      if ($(this).parents().eq(1).hasClass('subscription_wrap')) {
        $(this).parents().eq(1).addClass('active');
      }
      $(this).attr('aria-label', '관심 설정됨');
      // subscription_wrap
    } else {
      $(this).removeClass('active');
      if ($(this).parents().eq(1).hasClass('subscription_wrap')) {
        $(this).parents().eq(1).removeClass('active');
      }
      $(this).attr('aria-label', '관심 해제됨');
    }
  });
}

/**
 * 로그인 제안모듈
 * 연관페이지1: https://stg-www.joongang.co.kr/presidentvote2022?js=localmodule (상단 로그인 hover)
 * 연관페이지2: https://stg-www.joongang.co.kr/article/25098855?js=localmodule#home (기사 하단)
 * 연관페이지3: (draw)  현재 사용페이지 없음 https://stg-backoffice.joongang.co.kr/template/998
 */
export function loginProposal() {
  // 로그인 버튼 hover
  $('.logout').hover(function () {
    const pageType = window.utils.device.getPageType();
    if (
      $('#layer_login_popup').length &&
      !window.utils.cookie.has(window.COOKIE_NAME.IS_LOGIN_HISTORY) &&
      !$('.login_proposal:not(.hide)').length &&
      (pageType === 'pc' || pageType === 'wide')
    ) {
      $('#layer_login_popup').removeClass('hide');
      $('#layer_login_popup').fadeIn(function () {
        $(this).addClass('show');
        $('#layer_login_popup').removeAttr('style');
      });
    }
  });

  // 모바일 일때는 hover가 동작하지 않으므로 클릭으로 처리
  $('.logout').click(function (e) {
    if ($('#layer_login_popup').length) {
      const pageType = window.utils.device.getPageType();

      if (
        $('#layer_login_popup').length &&
        !window.utils.cookie.has(window.COOKIE_NAME.IS_LOGIN_HISTORY) &&
        !$('.login_proposal:not(.hide)').length &&
        (pageType === 'tablet' || pageType === 'mobile')
      ) {
        e.preventDefault();
        e.stopPropagation();
        $('#layer_login_popup').removeClass('hide');
        $('#layer_login_popup').fadeIn(function () {
          $(this).addClass('show');
          $('#layer_login_popup').removeAttr('style');
        });
      }
    }
  });

  // 로그인유도-하단 Drawer
  $('.login_proposal.drawer .btn_open').click(function () {
    const pageType = window.utils.device.getPageType();
    const $parent = $(this).closest('.drawer');
    if ($parent.hasClass('active')) {
      const height = pageType === 'pc' || pageType === 'wide' ? $parent.height() - 50 : $parent.height() - 44;
      $parent.animate({ marginBottom: -height }, 500, function () {
        $parent.removeClass('active');
        $parent.addClass('close');
        $parent[0].style.removeProperty('margin-bottom');
      });
    } else {
      $parent.addClass('active');
      $parent.removeClass('close');
      const height = pageType === 'pc' || pageType === 'wide' ? $parent.height() - 50 : $parent.height() - 44;
      $parent.css('margin-bottom', -height + 'px');
      $parent.addClass('active');
      $parent.animate({ marginBottom: -1 }, 800);
    }
  });

  // 로그인유도-플로팅 미니 팝업
  $('.login_proposal.plotting_popup').on('click', '.convenience_more, .btn_close', function () {
    const $this = $(this);
    const $popup = $this.closest('.plotting_popup');
    if ($this.hasClass('convenience_more')) {
      const minHeight = $popup.height();
      const maxHeight = $popup.addClass('active').height();

      $popup.find('.layer_body').css('height', minHeight);
      $popup.find('.layer_body').animate({ height: maxHeight }, 800, function () {
        $popup.find('.layer_body').removeAttr('style');
      });
    } else {
      $popup.fadeOut(800, function () {
        $popup.remove();
      });
      setLoginProposal('plottingMini');
    }
  });

  // 로그인유도-플로팅 배너 누르면 팝업 노출
  $('.login_proposal.plotting_banner').click(function (e) {
    e.stopPropagation();
    const $popup = $(this).find('.plotting_popup');
    if (!$popup.hasClass('active')) {
      $popup.addClass('active');
      $popup.hide();
      $popup.fadeIn(800, function () {
        $popup.removeAttr('style');
      });
    }
  });

  $('.login_proposal.plotting_banner').on('click', '.btn_close', function (e) {
    e.stopPropagation();
    const $popup = $(this).closest('.login_proposal');

    $popup.fadeOut(800, function () {
      $popup.remove();
    });

    setLoginProposal('plottingBanner');
  });
}

/**
 * 로그인 제안 팝업이나 배너를 조건에 따라 노출
 * 연관페이지1: https://stg-www.joongang.co.kr/presidentvote2022?js=localmodule (상단 로그인 hover)
 * 연관페이지2: https://stg-www.joongang.co.kr/article/25098855?js=localmodule#home (기사 하단)
 * 연관페이지3: draw  현재 사용페이지 없음 https://stg-backoffice.joongang.co.kr/template/998
 */
export function getLoginProposal() {
  const today = new Date();
  const date = new Date(today.setDate(today.getDate() - 7)).format('yyyyMMdd');
  const lastProposalDate1 = window.utils.cookie.get(window.COOKIE_NAME.LOGIN_PROPOSAL + '_plottingMini');
  const lastProposalDate2 = window.utils.cookie.get(window.COOKIE_NAME.LOGIN_PROPOSAL + '_plottingBanner');

  if ($('.login_proposal.plotting_mini').length && !lastProposalDate1 !== null && lastProposalDate1 < date) {
    showLoginProposal();
  } else if ($('.login_proposal.plotting_banner').length && !lastProposalDate2 !== null && lastProposalDate2 < date) {
    showLoginProposal();
  } else if ($('.login_proposal.drawer').length) {
    showLoginProposal();
  }
}

/**
 * 로그인 제안 팝업이나 배너를 노출
 * 연관페이지1: https://stg-www.joongang.co.kr/presidentvote2022?js=localmodule (상단 로그인 hover)
 * 연관페이지2: https://stg-www.joongang.co.kr/article/25098855?js=localmodule#home (기사 하단)
 * 연관페이지3: draw  현재 사용페이지 없음 https://stg-backoffice.joongang.co.kr/template/998
 */
export function showLoginProposal() {
  $('.login_proposal').css('display', 'none');
  $('.login_proposal').removeClass('hide');

  setTimeout(function () {
    setBottomLayout();
    $('.login_proposal').fadeIn(function () {
      $('.login_proposal').removeAttr('style');
      setBottomLayout();
    });
  }, 1500);
}

/**
 * 로그인 제안 팝업관련 쿠키 세팅
 * 연관페이지1: https://stg-www.joongang.co.kr/presidentvote2022?js=localmodule (상단 로그인 hover)
 * 연관페이지2: https://stg-www.joongang.co.kr/article/25098855?js=localmodule#home (기사 하단)
 * 연관페이지3: draw  현재 사용페이지 없음 https://stg-backoffice.joongang.co.kr/template/998
 */
export function setLoginProposal(name) {
  window.utils.cookie.set(window.COOKIE_NAME.LOGIN_PROPOSAL + '_' + name, new Date().format('yyyyMMdd'), 7);
}

/**
 * 로그인 제안 팝업관련 쿠키 세팅
 * 연관페이지1: https://stg-www.joongang.co.kr/presidentvote2022?js=localmodule (상단 로그인 hover)
 * 연관페이지2: https://stg-www.joongang.co.kr/article/25098855?js=localmodule#home (기사 하단)
 * 연관페이지3: draw  현재 사용페이지 없음 https://stg-backoffice.joongang.co.kr/template/998
 */
export function setIsLoginHistory() {
  if (window.utils.member.isMem() && !window.utils.cookie.has(window.COOKIE_NAME.IS_LOGIN_HISTORY)) {
    window.utils.cookie.set(
      window.COOKIE_NAME.IS_LOGIN_HISTORY,
      true,
      Infinity,
      window.utils.config.domainCookieInfo.PATH,
      window.utils.config.domainCookieInfo.DOMAIN
    );
  }
}

/**
 * 중앙앱 하단 툴바 사이즈 세팅 (앱에서 호출해줌)
 * 연관페이지: https://stg-www.joongang.co.kr/article/25098131 광고 레이아웃 및 하단 메뉴 노출시 레아아웃 변경
 */
export function setAppBottomHeight(height) {
  window.utils.cookie.set(
    window.COOKIE_NAME.APP_MENU_HEIGHT,
    height,
    Infinity,
    window.utils.config.domainCookieInfo.PATH,
    window.utils.config.domainCookieInfo.DOMAIN
  );
  setBottomLayout();
}

/**
 * 앱 하단 툴바에 영향을 받는 bottom에 붙는 레이어들 포지션 조정
 * 연관페이지: https://stg-www.joongang.co.kr/article/25098131 광고 레이아웃 및 하단 메뉴 노출시 레아아웃 변경
 */
export const setBottomLayoutCallback = [];
export function setBottomLayout() {
  let footerMargin = 0;
  let footerPaddingBottom = 0;
  let appMenuHeight = 0;

  const $menu_footer = $('.menu_popup .layer_footer .btn_group');

  // TODO: anchore 구글광고 적용후 그 다음 배포에 제거해야함
  if ($('.ad_wrap.fixed_bottom').length && $('.ad_wrap.fixed_bottom').css('display') !== 'none') {
    footerPaddingBottom = $('.ad_wrap.fixed_bottom').height();
    footerMargin += footerPaddingBottom;
  }

  if ($('.layer_jpod_player').length) {
    footerPaddingBottom += $('.layer_jpod_player').height();
    footerMargin += footerPaddingBottom;
  }

  if (window.utils.device.joongangApp) {
    /**
     * 앱일때
     * (앱 메뉴바 크기 예외처리)
     */
    appMenuHeight = calculateAppMenuHeight();

    if (window.isToolbar) {
      footerMargin += appMenuHeight;
      $('.ad_wrap.fixed_bottom').css('bottom', appMenuHeight);
      if ($('.full_popup .layer_popup.half_popup').length) {
        setTimeout(function () {
          $('.full_popup .layer_popup.half_popup').css('margin-bottom', appMenuHeight);
        }, 500);
      }
      $('.layer_jpod_player').css('padding-bottom', appMenuHeight);
      $('.full_popup .layer_popup.layer_jpod_playlist').css('padding-bottom', appMenuHeight);
    } else {
      $('.full_popup .layer_popup.half_popup').removeAttr('style');
      $('.ad_wrap.fixed_bottom').removeAttr('style');
      $('.layer_jpod_player').removeAttr('style');
      $('.full_popup .layer_popup.layer_jpod_playlist').removeAttr('style');
    }

    $menu_footer.css('padding-bottom', appMenuHeight);
    $('.layer_search .layer_body').css('padding-bottom', appMenuHeight);
    $('.full_popup .layer_popup.layer_video_play .layer_body').css('padding-bottom', appMenuHeight);
    $('.footer_info').css('padding-bottom', footerPaddingBottom + appMenuHeight);

    /**
     * 앱메뉴와 연관된 영역 계산 필요시 사용
     */
    setBottomLayoutCallback.forEach(cb => {
      cb({ appMenuHeight, isToolbar: window.isToolbar });
    });
  } else {
    /**
     * 앱이 아닐때  처리
     */
    /**
     * 광고나 jpod 플레이어를 위한 패딩 추가
     */
    if (footerPaddingBottom) {
      $('.footer_info').css('padding-bottom', footerPaddingBottom);
    } else {
      $('.footer_info').removeAttr('style');
    }

    /**
     * half popup 레이어 bottom 포지션 이동
     * (앵커광고 영역 처리)
     */
    moveHalfPopupBottomPos();
  }

  /**
   * 로긴제안 레이어 bottom 포지션 처리
   * (앵커광고 영역 처리)
   */
  moveBottomPosForLoginProposal({ isEnableAnchorAdverRef, footerMargin });
  moveTopLayerPos();

  /**
   * drag 비디오 레이어
   * (앵커광고 영역 처리)
   */

  const resetBottomForMobileVideoPosition = getJoongangRefProperty('resetBottomForMobileVideoPosition');
  if (resetBottomForMobileVideoPosition) {
    resetBottomForMobileVideoPosition();
  }
}

/**
 * 뉴스레터 발송시간 표기
 * 연관페이지: https://stg-www.joongang.co.kr/moappapi?js=localmodule#home
 * @param {string} date 날짜
 * @returns 변환된 발송시간
 */
export function senddate(date) {
  let timeText = '';
  if (date) {
    date = date.split(':');
    if (date[0] <= 12) {
      if (date[0] === '00') {
        date[0] = '자정';
      } else {
        const datenum = date[0].split('');
        if (datenum[0] === '0') {
          // eslint-disable-next-line prefer-destructuring
          date[0] = datenum[1];
        }
        timeText += '오전 ';
      }
    } else {
      date[0] = date[0] - 12;
      timeText += '오후 ';
    }
    timeText += date[0];
    if (date[0] !== '자정') {
      if (date[1] === '00') {
        timeText += '시';
      } else {
        timeText += '시 ' + date[1] + '분';
      }
    }
  }
  return timeText;
}

/**
 * 뉴스레터 발송 주기 표기
 * 연관페이지: https://stg-www.joongang.co.kr/moappapi?js=localmodule#home
 * @param {stinrg} send
 * @returns 변환된 발송 주기
 */
export function frequency(send) {
  let dayText = '';

  if (send) {
    if (send === 'D') {
      dayText += '매일 ';
    } else if (send === 'W') {
      dayText += '매주 ';
    } else if (send === 'M') {
      dayText += '매달 ';
    } else if (send === 'N') {
      // dayText += '';
      // 2023/2/17 e스트링 처리 해주는 코드로 파악되어 변경 with eslint error
      dayText = String(dayText);
    } else if (send === 'C') {
      dayText += '새 글 업데이트 시 ';
    } else if (send === 'C1') {
      dayText += '새 에피소드 업데이트 시 ';
    }
  }
  return dayText;
}

/**
 * 뉴스레터 발송 요일 표기
 * 연관페이지: https://stg-www.joongang.co.kr/moappapi?js=localmodule#home
 * @param {string} week
 * @returns 변환된 발송 요일
 */
export function weekname(week) {
  const dayArr = [];
  let weekText = '';
  if (week) {
    if (week.indexOf('0') > -1) {
      dayArr.push('일요일 ');
    }
    if (week.indexOf('1') > -1) {
      dayArr.push('월요일 ');
    }
    if (week.indexOf('2') > -1) {
      dayArr.push('화요일 ');
    }
    if (week.indexOf('3') > -1) {
      dayArr.push('수요일 ');
    }
    if (week.indexOf('4') > -1) {
      dayArr.push('목요일 ');
    }
    if (week.indexOf('5') > -1) {
      dayArr.push('금요일 ');
    }
    if (week.indexOf('6') > -1) {
      dayArr.push('토요일 ');
    }

    for (let i = 0; i < dayArr.length; i++) {
      weekText += dayArr[i];
    }
  }

  return weekText;
}

/**
 * 모바일 버전 메가메뉴 날씨 삭제
 * 연관페이지: https://stg-www.joongang.co.kr/article/25098131?js=localmodule#home (상단 메가메뉴)
 */
export function drawermobile() {
  const weatherli = $($('[data-evnt-lbl="날씨"]')[0]).parents('li');
  const pageType = window.utils.device.getPageType();

  if (pageType === 'mobile' || pageType === 'tablet') {
    weatherli.addClass('hide');
  } else if (weatherli.hasClass('hide')) {
    weatherli.removeClass('hide');
  }
}

/**
 * 리사이즈로 레이아웃 타입이 변경될때 실행됨 (with article.js)
 * 연관페이지: https://stg-www.joongang.co.kr/article/25098131?js=localmodule#home (상단 메가메뉴)
 * 구글광고 새로고침
 */
export function refreshAllSlots() {
  if (typeof window.googletag !== 'undefined') {
    window.googletag.cmd.push(function () {
      window.googletag.pubads().refresh();
    });
  }
}

/**
 * 구글광고 클리어
 * Todo 현재 사용부분 없어보임
 */
export function clearAllSlots() {
  if (typeof window.googletag !== 'undefined') {
    window.googletag.cmd.push(function () {
      window.googletag.pubads().clear();
    });
  }
}

/**
 * pageType 변경 체크 뷰포트에 따라 구분 범위가 틀려짐
 * 연관페이지: https://stg-www.joongang.co.kr/article/25098131?js=localmodule#home (리사이즈시)
 */
export function changeAdViewPoint(viewPoint) {
  let adRefresh = false;
  let pageType = window.utils.device.getPageType();
  switch (viewPoint) {
    case 2:
      if (pageType === 'mobile' || pageType === 'tablet') {
        pageType = 'mobile';
      } else {
        pageType = 'pc';
      }

      if (window.pageType === 'mobile' || window.pageType === 'tablet') {
        // window.pageType = 'mobile';
        setPageType('mobile');
      } else {
        // window.pageType = 'pc';
        setPageType('pc');
      }

      if (window.pageType !== pageType) {
        adRefresh = true;
        setPageType(pageType);
      }
      break;
    case 3:
      if (pageType === 'pc' || pageType === 'wide' || pageType === 'tablet') {
        pageType = 'pc';
      }

      if (window.pageType === 'pc' || window.pageType === 'wide' || window.pageType === 'tablet') {
        // window.pageType = 'pc';
        setPageType('pc');
      }
      if (window.pageType !== pageType) {
        adRefresh = true;
        // window.pageType = pageType;
        setPageType(pageType);
      }
      break;
    case 4:
      if (window.pageType !== pageType) {
        adRefresh = true;
        // window.pageType = pageType;
        setPageType(pageType);
      }
      break;
    default:
      adRefresh = false;
      break;
  }
  return adRefresh;
}

/**
 * 테스트: https://stg-www.joongang.co.kr/search?keyword=%ED%95%9C%EA%B5%AD
 * 뉴스섹션 이미지 온에러 속성
 * 이미지 로드 실패 시 기본 이미지로 대체해주는 함수
 * <img onerror="onErrorImg(this)" />
 * @param {Element} el element
 */
export function onErrorImg(el) {
  const reg = new RegExp(/.*?(\/_ir_\d*x\d*_\/?).*/);

  try {
    const irSize = el.src.match(reg) ? el.src.replace(reg, '$1') : '';
    const defImg = window.DEFAULT_IMG_16_9 + irSize;
    el.src = defImg;
    $(el).attr('srcset', el.src);
  } catch (e) {
    el.src = window.DEFAULT_IMG_16_9;
    $(el).attr('srcset', window.DEFAULT_IMG_16_9);
  }
}

/**
 * 온보딩 오피니언칼럼 리스트에 노출되는 구독상품, 하위 섹션에서 중복제거
 * 연관페이지: https://stg-backoffice.joongang.co.kr/template/1164
 * Todo: 온보딩 사용부분 없는걸로 보임
 * @param {string} typelistid 해당 영역 리스트 id
 */
export function relist(typelistid) {
  const opinion_ids = [];
  $('#onboard_opinion li button').each(function () {
    opinion_ids.push($(this).data('channelid'));
  });

  $(typelistid).each(function () {
    const $lis = $(this).find('li');
    $lis.each(function () {
      if (opinion_ids.indexOf($(this).find('button').data('channelid')) > -1) {
        // 같은게 있다
        const listid = '#' + $(this).parent().attr('id');
        $($(listid + ' li.hide')[0]).removeClass('hide');
        $(this).hide();
      }
    });
    if ($(this).find('li.hide').length < 1) {
      $(this).parent().find('.button_wrap_wide').addClass('hide');
    }
    if ($(this).find('li').length === $(this).find('li.hide').length) {
      $(this).parent().hide();
    }
  });
}

/**
 * 온보딩 팝업 구독패키지
 * 연관페이지: https://stg-backoffice.joongang.co.kr/template/1164
 * 연관페이지: https://stg-www.joongang.co.kr/_dts/oya_test?js=localmodule#home
 * Todo: 온보딩 사용부분 없는걸로 보임
 * @param {string} type
 * @param {number} page
 */
export function onboardsub(type, page) {
  // #onboard_tab1 구독 패키지
  window.utils.ajax.get({
    url: window.utils.config.apiPath + 'subscribe',
    data: { type, count: 20, page }, // type : O:오피니언/컬럼  S:연재 I:이슈 J:팟캐스트 R:리포터
    success(result) {
      let pkglist = '';
      const typelistid =
        type === 'O'
          ? '#onboard_opinion'
          : type === 'S'
          ? '#onboard_series'
          : type === 'I'
          ? '#onboard_issue'
          : type === 'J'
          ? '#onboard_jpod'
          : type === 'R'
          ? '#onboard_reporter'
          : '';
      if (result._TOTAL._DATA === 0) {
        $(typelistid).parent().hide();
      } else {
        const morebtn = $('[data-tabpanel=tabPanel1][data-more-type=' + type + ']');
        morebtn.attr('data-total', result._TOTAL._DATA);
        if (page === 1 && result._TOTAL._DATA < 4) {
          morebtn.parent().addClass('hide');
        } else if (page > 1 && result._TOTAL._DATA < 20 * page) {
          morebtn.parent().addClass('hide');
        }
        pkglist = result._DATA.map(function (item, index) {
          item.HIDE_LIST = page === 1 && index > 2 ? 'hide' : '';
          item.SCB_TARGET_NAME = window.utils.escapeHtml(item.SCB_TARGET_NAME);
          item.PKG_DIVNAME =
            item.PKG_DIV === 'S' ? 'series' : item.PKG_DIV === 'I' ? 'issue' : type === 'J' ? 'podcast' : 'journalist';
          item.PKG_TITLE = window.utils.escapeHtml(item.PKG_TITLE);
          item.SCB_TARGET_DESC = window.utils.escapeHtml(item.SCB_TARGET_DESC);

          return item;
        });
      }
      if (pkglist.length > 0) {
        $('#onboard_subpkg_tmpl').tmpl(pkglist).appendTo(typelistid);
        setActiveMySubs();
        relist(typelistid);
      }
    },
  });
}

/**
 * Todo: 온보딩 사용부분 없는걸로 보임
 * 연관페이지: https://stg-backoffice.joongang.co.kr/template/1164
 * 온보딩 팝업 뉴스레터
 * @param {string} type
 * @param {number} page
 */
export function onboardletter(type, page) {
  // #onboard_tab2 뉴스레터
  window.utils.ajax.get({
    url: window.utils.config.apiPath + 'newsletter',
    data: { type, count: 20, page }, // type : O:오리지널 B:브리핑 N:알림
    success(result) {
      let letterlist = '';
      const morebtn = $('[data-tabpanel=tabPanel2][data-more-type=' + type + ']');
      const typelistid = type === 'O' ? '#onboard_org' : type === 'B' ? '#onboard_blf' : '#onboard_alm';
      morebtn.attr('data-total', result._TOTAL._DATA);
      if (page === 1 && result._TOTAL._DATA < 4) {
        morebtn.parent().addClass('hide');
      } else if (page > 1 && result._TOTAL._DATA < 20 * page) {
        morebtn.parent().addClass('hide');
      }

      letterlist = result._DATA.map(function (item, index) {
        item.HIDE_LIST = page === 1 && index > 2 ? 'hide' : '';
        item.BLANK_IMG = window.utils.getSrc(window.BLANK_IMG, 760, 424);
        item.LETTER_IMG = window.utils.getSrc(item.LETTER_IMG, 760, 424);
        item.LETTER_NAME = window.utils.escapeHtml(item.LETTER_NAME);
        item.LETTER_DESC = window.utils.escapeHtml(item.LETTER_DESC);

        // 날짜변환
        item.RENDER_PERIOD = item.SEND_PERIOD
          ? item.CHANNEL_TYPE === 'JPOD' && item.SEND_PERIOD === 'C'
            ? 'C1'
            : item.SEND_PERIOD
          : '';
        item.RENDER_PERIOD = frequency(item.RENDER_PERIOD);
        item.RENDER_TIME = item.SEND_TIME && item.SEND_PERIOD !== 'C' ? senddate(item.SEND_TIME) : '';
        item.RENDER_DAY = item.SEND_DAY ? weekname(item.SEND_DAY) : '';
        item.LETTER_SENDDATE = item.RENDER_PERIOD + item.RENDER_DAY + item.RENDER_TIME;

        return item;
      });
      if (letterlist.length > 0) {
        $('#onboard_newsletter_tmpl').tmpl(letterlist).appendTo(typelistid);
        $('img').lazyload(window.LAZY_LOAD_DEFAULT_OPTION);
        setActiveMySubs();
      }
    },
  });
}

/**
 * hide 클래스 제거
 */
export function showEle(params) {
  $(this).each(function () {
    if ($(this).hasClass('hide')) {
      $(this).removeClass('hide');

      // hide 클래스만 제거하는 것이 아닌, show 클래스를 추가해 주는 옵션도 추가
      // const { isAddShow } = { ...params };
      // isAddShow && $(this).addClass('show');
      if (params && params.isAddShow) {
        $(this).addClass('show');
      }
    }
  });
}

/**
 * hide 클래스 추가
 */
export function hideEle() {
  $(this).each(function () {
    if (!$(this).hasClass('hide')) {
      $(this).addClass('hide');
    }
  });
}

/**
 * 돔 콘텐트 로디드 핸들러
 */
export function handleDomContentLoaded() {
  window.utils.format.init();

  /**
   * 앵커 광고영역 크기 사이즈 옵저버
   * addBottomPosForLoginProposal(로긴제안 레이어영역 추가)
   * addBottomPosForhalfPopup(half popup 영역 추가)
   * addTopLayerPosPopup(검색, 메가메뉴)
   */
  anchorAdverLayoutObserver(
    [addBottomPosForLoginProposal, addBottomPosForhalfPopup, addTopLayerPosPopup],
    setBottomLayout
  );

  // 마이페이지에 로그인 안하고 접근시 로그인 페이지로 이동
  const isMypage = /^\/(mynews|myfeed)/.test(location.pathname);
  const isMember = window.utils.member.isMem();
  if (isMypage && !isMember) {
    window.jaLogin();
  }

  // 앱에서 보이면 안되는 메뉴 감추기
  if (!window.utils.device.joongangApp) {
    $('.app_menu_hidden').removeClass('hide');
  }

  // 구글 광고슬롯 갱신용 디바이스 width
  // window.pageType = window.utils.device.getPageType();
  setPageType(window.utils.device.getPageType());

  setIsLoginHistory();

  // 로그인한 앱 사용유저 광고제거 및 광고 숨김 해제
  if (window.utils.device.joongangApp && window.utils.member.isMem()) {
    $('.ad_wrap, .ad_remove').remove();
  } else {
    $('.ad_wrap:not(#pcWidget1)').removeClass('hide');
  }

  // 광고 레이아웃 및 앱 하단메뉴 노출 시 레이아웃 제어
  setBottomLayout();
  if (typeof window.ResizeSensor !== 'undefined') {
    if ($('.ad_wrap.fixed_bottom').length) {
      new window.ResizeSensor($('.ad_wrap.fixed_bottom')[0], function () {
        setBottomLayout();
      });
    }
  }

  // image lazyload
  $.fn.lazyload &&
    (function () {
      $('img').lazyload(window.LAZY_LOAD_DEFAULT_OPTION);
    })();

  /*
   * https://thejoongang.atlassian.net/wiki/spaces/plan1/pages/22741889/Phase1+v0.1
   * 아티클, 뉴스레터, PDF, AtoZ 타입의 content영역 안에서 copy/paste 이벤트 처리
   */
  copyPaste();

  // sticky
  setSticky();

  // 스와이퍼 호출 타이머
  // let CommonSwiperTimer = null;

  // 윈도우 스크롤 이벤트
  $(window).scroll(function () {
    const current = $(window).scrollTop();

    if (window.windowOld !== current) {
      $('html, body').css('transform', 'none');
      if (current === 0) {
        // window.isToolbar = true;
        setIsToolbar(true);
        setBottomLayout();
      } else if (current > 1) {
        // window.isToolbar = current >= this.windowOld ? false : true;
        setIsToolbar(current >= window.windowOld ? false : true);
        setBottomLayout();
      }
    }

    // 스크롤시 상단 메뉴 스와이퍼 재실행 매번 실행되지 않아도 되서 타이머 추가.
    /*
    clearTimeout(CommonSwiperTimer);
    CommonSwiperTimer = setTimeout(function () {
      createCommonSwiper();
    }, 500);
    */
    // this.windowOld = current;
    setWindowOld(current);
  });

  // 메뉴 스크롤 이벤트
  // 상단 메가메뉴 스크롤
  let menuOld;
  $('.menu_popup .scroll').scroll(function () {
    const current = $(this).scrollTop();
    if (menuOld !== current) {
      setIsToolbar(current >= menuOld ? false : true);
      setBottomLayout();
    }
    menuOld = current;
  });

  // 윈도우 resize 이벤트
  $(window).resize(function () {
    resizeMenuPopup();
    setBottomLayout();
    swiperScollYn();
    drawermobile();

    // 스크롤시 상단 메뉴 스와이퍼 재실행 매번 실행되지 않아도 되서 타이머 추가.
    /*
    clearTimeout(CommonSwiperTimer);
    CommonSwiperTimer = setTimeout(function () {
      createCommonSwiper(); }, 500);
    */
  });

  // 모바일 drawer 메뉴 날씨 가림
  drawermobile();

  // li형 select box
  toggleDropdown();

  // 레이어팝업 내부 클릭 시 팝업 닫힘 방지
  $(document).on('click', '.layer_popup, button', function (e) {
    e.stopPropagation();
  });

  // 레이어팝업 내부 클릭 시 팝업 닫힘 방지
  if (window.NEWSLETTER) {
    $(document).on('click', '.dropdown_subscribe, button', function (e) {
      e.stopPropagation();
    });
  }

  $(document).on('click', '.btn_close', function () {
    closeLayer($(this));
  });

  // 다음에 볼게요 버튼 클릭 시
  $(document).on('click', '.btn_pass', function () {
    if ($(this).parents('.half_popup')) {
      closeLayer($(this));
    }
    $(this).parents('.layer_alert').removeClass('show').addClass('hide');
  });

  // datepicker
  const $datepicker = $('.date_wrap input');
  if ($datepicker.length > 0) {
    $datepicker.datepicker({
      maxDate: 0, // 오늘까지만 선택 가능 (기본)
      buttonImageOnly: true,
      buttonImage: window.CALENDER_IMG,
      showOn: 'both',
      onSelect() {
        $(this).change();
      },
    });

    if ($datepicker.val() === '') {
      $datepicker.datepicker('setDate', 'today');
    }

    $('.layer_popup .date_wrap input').on('focus', function () {
      $('.ui-datepicker').addClass('on_popup');
    });
  }

  // 기사 리스트 상단 달력
  articleListDatepicker();

  // 공유 연결
  shareSns();

  // 부동산 예외처리 ㅎ
  if (window.IS_REALESTATE) {
    $('#layer_share li').click(function (e) {
      e.preventDefault();
      if ($(this).find('.ico_sns_ktalk').length > 0) {
        window.utils.scriptLoader({
          id: 'kakao',
          src: 'https://developers.kakao.com/sdk/js/kakao.min.js',
          onload() {
            window.utils.share.kakaotalk({ url: location.href });
          },
        });
      } else if ($(this).find('.ico_sns_face').length > 0) {
        window.utils.share.facebook({ url: location.href });
      } else if ($(this).find('.ico_sns_twt').length > 0) {
        window.utils.share.twitter({ url: location.href });
      } else if ($(this).find('.ico_sns_url').length > 0) {
        window.utils.share.copy(e.target, { url: location.href });
        $(this).parents('#layer_share').find('.btn_close').trigger('click');
      } else if ($(this).find('.ico_sns_mail').length > 0) {
        window.utils.share.mail({ url: location.href });
      } else if ($(this).find('.ico_sns_band').length > 0) {
        window.utils.share.naverband({ url: location.href });
      }
    });
  }

  // 공통 스와이퍼
  // createCommonSwiper();

  // 스와이퍼 스크롤바 노출 처리
  swiperScollYn();

  // dropdown open 시 key 입력 event
  $('.layer_popup').keydown(function (event) {
    trapTabKey($(this), event);
  });

  // 탭
  if ($.tabs) {
    $.tabs.init();
  }

  // 위젯 sns
  const $widgetSns = $('[data-widget-type="sns"]');
  if ($widgetSns.length > 0) {
    $widgetSns.each(function () {
      // utils widgetDepartmentSns 구현이 있음
      $(this).widgetDepartmentSns();
    });
  }

  // 내 구독상품 목록, 상단 로그인 표시, 로그인유도
  if (window.utils.member.isMem()) {
    const nick = window.utils.member.getInfo().nick || '';
    $('.btn_user em').each(function () {
      $(this).text(nick);
    });
    $('#header .user, .btn_user').removeClass('hide');
    $('footer .logout').removeClass('hide');
  } else {
    $('#header .logout').removeClass('hide');
    $('footer .login').removeClass('hide');
    getLoginProposal();
    loginProposal();
  }

  // 북마크 버튼 클릭시 북마크 표시하기
  $(document).on('click', '.btn_bookmark:button', function () {
    const $_this = $(this);
    window.utils.bookmark.bookmarkProc($_this);
  });

  // 나의 북마크 가져와서 북마크 표시하기
  if (!window.utils.empty(sessionStorage.getItem(window.CHOICE_BOOKMARK)) && !window.utils.isBackForwardPage()) {
    try {
      const bookmarkInfo = JSON.parse(sessionStorage.getItem(window.CHOICE_BOOKMARK));
      // 화면내의 북마크 버튼을 찾아 이벤트를 호출한다. 없으면.... 그리고 아무일도 없었다.
      $('.btn_bookmark:button[data-cid="' + bookmarkInfo.cid + '"][data-ctype="' + bookmarkInfo.ctype + '"]').trigger(
        'click'
      );
    } catch (e) {
      console.warn(e);
    }
    sessionStorage.removeItem(window.CHOICE_BOOKMARK);
  }

  // 북마크 가져오기
  if ($('.btn_bookmark').length > 0) {
    window.utils.bookmark.getMyScrap();
  }

  // 관심사 정보 가져오기. 화면에 관심 버튼이 존재하지 않는 경우, 데이터 불러오지 않음.
  if ($('.btn_myfavo, .btn_myfavo_plus').length > 0) {
    window.utils.myFavo.getMyFavoList();
  }

  $('.copyright').click(function () {
    return false;
  });

  /**
   * 온보딩 코드 (Todo: 현재 사용안함)
   */
  if ($('#layer_onboard #onboard_tab2 .date')) {
    for (let i = 0; i < $('#onboard_tab2 .date').length; i++) {
      const fulldatelist = $($('#onboard_tab2 .date')[i]).text().replace(/\n/gi, '').trim();
      const datelist = fulldatelist.split(' ');
      let senddyatime = '';

      if (datelist[0] === 'date') {
        senddyatime += frequency(datelist[1]);
        senddyatime += weekname(datelist[2]);
        senddyatime += senddate(datelist[3]);
        $($('#onboard_tab2 .date')[i]).html(senddyatime);
        if (senddyatime === '') {
          $($('#onboard_tab2 .date')[i]).addClass('hide');
        }
      }
    }
  }

  /**
   * 온보딩 코드 (Todo: 현재 사용안함)
   * 온보딩 팝업 리스트 tmpl
   */
  // const firstlogin = window.utils.cookie.get(window.COOKIE_NAME.JA_MEM_FIRST_LOGIN_YN);
  const firstlogin = 'Y';
  if (firstlogin === 'Y' && $('#onboard_newsletter_tmpl').length > 0 && $('#onboard_subpkg_tmpl').length > 0) {
    $('#layer_onboard .nav_link').attr('data-evnt-lbl', location.href);
    const subpkgtype = ['O', 'S', 'I', 'T', 'J', 'R'];
    const lettertype = ['O', 'B', 'N'];
    for (let i = 0; i < subpkgtype.length; i++) {
      onboardsub(subpkgtype[i], 1);
    }
    for (let i = 0; i < lettertype.length; i++) {
      onboardletter(lettertype[i], 1);
    }
    $('#layer_onboard').removeClass('hide').addClass('show');
    $('html, body').addClass('overflow_hidden');
    setDisableBodyScroll();
  }

  /**
   * 온보딩 코드 (Todo: 현재 사용안함)
   * 온보딩 팝업 닫기 버튼
   */
  $('#layer_onboard .btn_close').on('click', function () {
    $('#layer_onboard').addClass('hide').removeClass('show');
    window.utils.cookie.remove(window.COOKIE_NAME.JA_MEM_FIRST_LOGIN_YN, '/', '.joongang.co.kr');
    if ($('#layer_onboard .btn_subscribe.active').length > 0) {
      const msg = '구독해 주셔서 감사합니다.<br>내 구독을<br>둘러보시겠어요?';
      window.messageUtil.confirm(
        { message: msg },
        function () {
          location.href = window.utils.config.webPath + '/myfeed';
        },
        function () {
          location.reload();
        }
      );
    } else {
      location.reload();
    }
    $('body, html').removeClass('overflow_hidden');
    setEnableBodyScroll();
  });

  $(document).on('click', function () {
    autoCloseLayer();
    closeLayer($('.menu_popup .btn_close'));
  });

  $(document).on('click', '.fixed_bottom .btn_close', function (e) {
    e.preventDefault();
    e.stopPropagation();

    $('.fixed_bottom').fadeOut(function () {
      $('.fixed_bottom').remove();
      setBottomLayout();
    });
  });

  /**
   * 온보딩 코드 (Todo: 현재 사용안함)
   * 온보딩 더보기 버튼
   */
  $('#layer_onboard').on('click', '[data-more-type]', function () {
    const type = $(this).attr('data-more-type');
    const page = Number($(this).attr('data-page'));

    if (page === 1) {
      const listid = $(this).attr('data-list');
      if ($(this).attr('data-total') <= 20) {
        $(this).parent().addClass('hide');
      }
      $('#' + listid)
        .find('li')
        .removeClass('hide');
      $(this).attr('data-page', page + 1);
    } else if ($(this).attr('data-tabpanel') === 'tabPanel1') {
      // 구독패키지
      onboardsub(type, page);
    } else {
      // 뉴스레터
      onboardletter(type, page);
    }
  });

  // 온보딩 팝업 탭 클릭
  $('#layer_onboard #tabPanel1').on('click', function () {
    $('#layer_onboard .tabPanel1').addClass('active');
    $('#layer_onboard .tabPanel2').removeClass('active');
  });
  $('#layer_onboard #tabPanel2').on('click', function () {
    $('#layer_onboard .tabPanel2').addClass('active');
    $('#layer_onboard .tabPanel1').removeClass('active');
  });
}

function copyPaste() {
  // 복사 이벤트 감지 영역 설정 : copyArea,
  // 일반 아티클, 플러스 아티클, pdf타입, 뉴스레터 타입 : .article_body, .article_header
  // atoz 타입 : .faq.children().not(.faq_foot), .visual_header_wrap
  const isAtoZ = window?.dataLayer && window.dataLayer[0]?.contentData?.articleTemplate === 'AtoZ';
  const atozArea = isAtoZ ? $('.faq').children().not('.faq_foot').add('.visual_header_wrap') : $();
  const copyArea = isAtoZ ? atozArea : $('.article_body, .article_header');

  copyArea.on('copy', function (e) {
    // 기존 클립보드 내용 가져오기
    const copiedText = window.getSelection().toString();
    // 페이지 url 가져오기
    const pageUrl = window?.dataLayer[0]?.pageData?.pageUrl;
    // 추가할 내용
    const additionalText = ' [출처:중앙일보] ' + pageUrl;
    // 수정된 내용을 클립보드에 복사하기
    const combinedText = copiedText + additionalText;
    e.originalEvent.clipboardData.setData('text/plain', combinedText);
    if (copiedText.length >= 500) {
      e.preventDefault();
      window.messageUtil.alert({
        message:
          '문구가 복사되었습니다. <br>중앙일보(주)는 본 콘텐트에 대한 <br>저작권 등 일체의 권리를 보유하였으므로 <br>사용에 주의하시기 바랍니다.',
      });
    } else if (copiedText.length > 0) {
      e.preventDefault();
      // window.messageUtil.toast({ message: '문구가 복사되었습니다.' });
    }
  });
}
