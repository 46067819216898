import { APP_PDF_DOWNLOAD_COMPARE_VERSION, ACCOUNT_PAGE_DEV_URL, ACCOUNT_PAGE_URL } from '@paidPages/common/constants';
import { pageType, joongangUtils } from '@paidPages/common/references';

export function clsx(classNameList) {
  return classNameList.filter(valid => valid).join(' ');
}

export function isEmpty(value) {
  return value === undefined || value === null || value === '';
}

export function isEmpty2(value) {
  return value === undefined || value === null || value?.length === 0 || Object.keys(value).length === 0;
}

export function setJoongangRefProperty(key, value) {
  if (!window.joongang) {
    window.joongang = {};
  }
  if (!window.joongang.shareRef) {
    window.joongang.shareRef = {};
  }

  window.joongang.shareRef[key] = value;
}

export function getJoongangRefProperty(key) {
  if (window?.joongang?.shareRef) {
    return window.joongang.shareRef[key] || null;
  }

  return null;
}

export function addStickyCallbacks(callback) {
  const shareRef = window?.joongang?.shareRef;
  if (shareRef?.stickyCalllbacks) {
    shareRef?.stickyCalllbacks.push(callback);
  } else {
    setJoongangRefProperty('stickyCalllbacks', [callback]);
  }
}

export function removeStickyCallbacks(callback) {
  const stickyCalllbacks = window?.joongang?.shareRef?.stickyCalllbacks;
  if (stickyCalllbacks) {
    const index = stickyCalllbacks.indexOf(callback);

    if (index > -1) {
      stickyCalllbacks.splice(index, 1);
    }
  }
}

export function moveLoginUrl() {
  const ACCOUNT_URL = getAccountUrl();
  const LOGIN_URL = `${ACCOUNT_URL}login?targetURL=${encodeURIComponent(window.location.href)}`;

  location.href = LOGIN_URL;
}

export function moveLogoutUrl() {
  window.jaLogout();
}

export function logoutAndMoveLogin() {
  joongangUtils.current?.current?.member?.removeMemInfo();
  moveLoginUrl();
}

export function convertServerTimeFormat(target) {
  if (!target) return null;
  return target.replace(/([0-9]{4})([0-9]{2})([0-9]{2})\s([0-9]{2}:[0-9]{2}:[0-9]{2})\.[0-9]*/, '$1-$2-$3T$4');
}

export function getCookie(key) {
  if (!key) {
    return null;
  }

  let cookie =
    document.cookie.replace(
      new RegExp(`(?:(?:^|.*;)\\s*${encodeURIComponent(key).replace(/[-.+*]/g, '\\$&')}\\s*\\=\\s*([^;]*).*$)|^.*$`),
      '$1'
    ) || null;
  if (cookie) {
    try {
      cookie = decodeURIComponent(cookie);
    } catch (e) {
      cookie = decodeURIComponent(unescape(cookie)); // escape 처리된 문자열이 저장된 경우, catch.
    }
  }

  return cookie;
}

export function setCookie({ key, value, end, path, domain, secure }) {
  let expires = '';
  let domainValue = domain;

  if (!key || /^(?:expires|max-age|path|domain|secure)$/i.test(key)) {
    return false;
  }

  if (!domainValue) {
    domainValue = '.joongang.co.kr';

    if (location.host.match(/localhost/)) {
      domainValue = '';
    }
  }

  if (end) {
    switch (end.constructor) {
      case Number:
        expires = end === Infinity ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT' : `; max-age=${end * 60 * 60 * 24}`;
        break;
      case String:
        expires = `; expires=${end}`;
        break;
      case Date:
      default:
        expires = `; expires=${end.toUTCString()}`;
        break;
    }
  }

  const domainString = domainValue ? `; domain=${domainValue}` : '';
  const pathString = `; path=${path ? path : '/'}`;
  const secureString = secure ? '; secure' : '';
  const keyValueString = `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  const cookieString = `${keyValueString}${expires}${domainString}${pathString}${secureString}`;

  document.cookie = cookieString;

  return true;
}

export function isBackForwardPage() {
  const { performance } = window;
  const { navigation } = performance;

  if (navigation) {
    return navigation.type === navigation.TYPE_BACK_FORWARD;
  } else if (performance.getEntriesByType) {
    return performance.getEntriesByType('navigation')[0].type === 'back_forward';
  }

  return false;
}

export function isReload() {
  const { navigation } = performance;
  if (navigation) {
    return navigation.type === navigation.TYPE_RELOAD;
  } else if (performance.getEntriesByType) {
    return performance.getEntriesByType('navigation')[0].type === 'reload';
  }

  return false;
}

export function isFromLoginPage() {
  const { referrer } = document;

  if (referrer) {
    const regex = new RegExp(`(${ACCOUNT_PAGE_DEV_URL}|${ACCOUNT_PAGE_URL})`);

    return regex.test(referrer);
  }

  return false;
}

/**
 * 두 시간 차이를 계산해서 diff(default: 24시(하루))이내로 차이나면 true, 아니면 false 반환
 * @param startTime : 비교 대상 첫번째 시간 (문자열로 전달시 YYYY-MM-DD HH:MM:SS 형태로 전달)
 * @param endTime : 비교 대상 두번째 시간 (문자열로 전달시 YYYY-MM-DD HH:MM:SS 형태로 전달)
 * @param diff : 허용할 두 시간 차이. 허용 범위 내에 들어오면 true 반환, 그렇지 않으면 false 반환
 *
 * 입력 받는 두 시간 값 중의 하나라도 비어 있거나 null인 경우, 또 올바르지 않은 시간 값인 경우, null값 반환
 * diff 값 미 전달 시, 24시간 기본 설정.
 */
export function checkInTimeDifference(startTime, endTime, diff = 24) {
  try {
    if (!endTime || !startTime) {
      return null;
    }
    // eslint-disable-next-line no-nested-ternary
    const st = startTime instanceof Date ? startTime : typeof startTime === 'string' ? startTime.toDate() : null;
    // eslint-disable-next-line no-nested-ternary
    const et = endTime instanceof Date ? endTime : typeof endTime === 'string' ? endTime.toDate() : null;

    if (!st || !et) {
      return null;
    }

    return et - st < diff * 60 * 60 * 1000;
  } catch (e) {
    console.warn('CHECKINTIMEDIFF', e);
    return null;
  }
}

/**
 * Date 날짜 형식 값 넣어주면 YYYY.MM.DD HH:MM 형태의 스트링 포맷으로 반환하는 함수..
 *
 * @param datetime
 */
export const getDateTimeString = (datetime = new Date(), isShortType = false) => {
  const date = datetime ? datetime : new Date();

  const year = date.getFullYear();
  const month = `0${1 + date.getMonth()}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  const hour = `0${date.getHours()}`.slice(-2);
  const minute = `0${date.getMinutes()}`.slice(-2);

  if (isShortType) {
    return `${year}.${month}.${day}`;
  }

  return `${year}.${month}.${day} ${hour}:${minute}`;
};

/**
 * 시간 문자열(YYYY-MM-DD HH:MM)값 넣어주면 방금 전, 몇분 전, 몇시간 전 그 외에는 시간 정보를 표기해주는 함수
 * 라이브 기사에서 사용
 *
 * @param stringDate
 * @param GLOBAL_SERVER_TIME - 원래 기존 대선 프로젝트에서는 별도로 SERVER 시간을 받아서 전역 세팅함.. 여기서는 일단 파라메터로 받는 방식으로 처리..
 */
export const getTimeAgo = (stringDate, GLOBAL_SERVER_TIME, isShortType) => {
  try {
    if (stringDate === '') {
      return stringDate;
    }

    const st = new Date(stringDate.replace(/-/g, '/'));
    let diff = ((GLOBAL_SERVER_TIME ? Date.parse(GLOBAL_SERVER_TIME) : Number(new Date())) - Number(st)) / 1000;
    // let diff = (Number(new Date()) - Number(st)) / 1000;

    if (diff < 60) {
      return '방금 전';
    }

    diff = Math.ceil(diff / 60);
    if (diff < 60) {
      return `${diff}분 전`;
    }

    diff = Math.ceil(diff / 60);
    if (diff < 24) {
      return `${diff}시간 전`;
    }

    return getDateTimeString(st, isShortType);
  } catch (e) {
    console.warn('GET TIME AGO', e);
    // Do Nothing
  }

  return stringDate;
};

/**
 * 밀리세컨드 숫자 값을 입력하면 MM:SS 또는 HH:MM:SS 형태의 시간 값으로 변환한 후, 반환.
 *
 * @param milliseconds
 * @returns {`${string}:${string}`|`${string}:${string}:${string}`}
 */
export const convertMillisecondsToMMSS = milliseconds => {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const remainingSeconds = totalSeconds % 3600;
  const minutes = Math.floor(remainingSeconds / 60);
  const seconds = remainingSeconds % 60;

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');

  if (hours > 0) {
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }
  return `${formattedMinutes}:${formattedSeconds}`;
};

/**
 * API 등으로부터 전달받은 문자열 중, ASCII CODE값 등을 원 HTML 코드로 변환(디코딩) 해 준다.
 *
 * @param htmlString: 디코딩할 문자열
 * @return: 디코딩된 결과 문자열
 */
export const decodeHtml = (htmlString, nl2br = false) => {
  if (!htmlString) {
    return '';
  }

  let asciiDecodedHtml = htmlString.replace(/(&#(\d+);)/g, (match, capture, charCode) => String.fromCharCode(charCode));

  if (nl2br) {
    asciiDecodedHtml = asciiDecodedHtml.replace(/\n/g, '<br />');
  }

  return asciiDecodedHtml
    .replace(/&lt;/g, `<`)
    .replace(/&gt;/g, `>`)
    .replace(/&amp;/g, `&`)
    .replace(/&quot;/g, '"')
    .replace(/&ldquo;/g, '“')
    .replace(/&rdquo;/g, '”')
    .replace(/&rsquo;/g, "'")
    .replace(/&lsquo;/g, "'")
    .replace(/&middot;/g, '·')
    .replace(/&hellip;/g, '…')
    .replace(/&apos;/g, `'`)
    .replace(/&rarr;/g, `→`)
    .replace(/&rArr;/g, `⇒`)
    .replace(/&larr;/g, `←`)
    .replace(/&lArr;/g, `⇐`);
};

export const decodeHtmlForGtmLabel = (htmlString, nl2br = false) => {
  if (!htmlString) {
    return '';
  }

  let asciiDecodedHtml = htmlString.replace(/(&#(\d+);)/g, (match, capture, charCode) => String.fromCharCode(charCode));

  if (nl2br) {
    asciiDecodedHtml = asciiDecodedHtml.replace(/\n/g, '<br />');
  }

  return asciiDecodedHtml
    .replace(/&lt;/g, `<`)
    .replace(/&gt;/g, `>`)
    .replace(/&amp;/g, `&`)
    .replace(/&quot;/g, '“')
    .replace(/&ldquo;/g, '“')
    .replace(/&rdquo;/g, '”')
    .replace(/"/g, '“')
    .replace(/&rsquo;/g, "'")
    .replace(/&lsquo;/g, "'")
    .replace(/&middot;/g, '·')
    .replace(/&hellip;/g, '…')
    .replace(/&apos;/g, `'`)
    .replace(/&rarr;/g, `→`)
    .replace(/&rArr;/g, `⇒`)
    .replace(/&larr;/g, `←`)
    .replace(/&lArr;/g, `⇐`);
};

/**
 * Moka 구독 정보 업데이트 함수 호출
 * @param time
 */
export const callMokaSetActiveMySubs = (time = 500) => {
  setTimeout(() => {
    if (window) {
      // eslint-disable-next-line
      // @ts-ignore
      if (window.setJoongangPlusActiveMySubs) {
        // eslint-disable-next-line
        // @ts-ignore
        window.setJoongangPlusActiveMySubs();
      }
    }
  }, time);
};

/**
 * Moka 관심 정보 업데이트 함수 호출
 * @param time
 */
export const callMokaSetActiveMyFavorites = (time = 500) => {
  setTimeout(() => {
    if (window) {
      // eslint-disable-next-line
      // @ts-ignore
      if (window.setJoongangPlusActiveMyFavorites) {
        // eslint-disable-next-line
        // @ts-ignore
        window.setJoongangPlusActiveMyFavorites();
      }
    }
  }, time);
};

/**
 * GA 데이터 세팅
 * @param category
 * @param action
 * @param label
 * @return {{"data-evnt-act", "data-evnt-ctg", "data-evnt-lbl"}}
 */
export const setGAEventSet = (category, action, label) => {
  return {
    'data-evnt-ctg': category || '',
    'data-evnt-act': action || '',
    'data-evnt-lbl': label || '-',
  };
};

/**
 * GA impression 데이터 세팅
 * @param category
 * @param action
 * @param label
 * @return {{"data-evnt-imp-act", "data-evnt-imp-ctg", "data-evnt-imp-lbl"}}
 */
export const setGAImpressionEventSet = (category, action, label) => {
  return {
    'data-evnt-imp-ctg': category || '',
    'data-evnt-imp-act': action || '',
    'data-evnt-imp-lbl': label || '-',
  };
};

/**
 * GA 데이터 세팅 (리얼 DOM ELEMNT)
 * @param {HTMLElement} targetElement
 * @param {Object} info
 *   @param {string} info.category
 *   @param {string} info.action
 *   @param {string} info.label
 */
export function setGAEventSetForRealElement(targetElement, { category, action, label }) {
  const att = setGAEventSet(category, action, label);

  Object.entries(att).forEach(([key, value]) => {
    targetElement.setAttribute(key, value);
  });
}

export const getApiDomain = () => {
  const host = location.host || '';

  if (host.indexOf('dev-') === 0) {
    return 'https://dev-api.joongang.co.kr';
  } else if (host.indexOf('stg-') === 0) {
    return 'https://stg-api.joongang.co.kr';
  } else if (host.match(/local-/)) {
    return 'https://local-api.joongang.co.kr';
  } else if (host.match(/localhost/)) {
    return 'http://localhost:8081';
  }

  return 'https://api.joongang.co.kr';
};

export const getAccountUrl = () => {
  const host = location.host || '';

  if (host.indexOf('dev-') === 0) {
    return 'https://dev-account.joongang.co.kr/';
  } else if (host.indexOf('stg-') === 0) {
    return 'https://dev-account.joongang.co.kr/';
  } else if (host.match(/local-/)) {
    return 'https://dev-account.joongang.co.kr/';
  } else if (host.match(/localhost/)) {
    return 'https://dev-account.joongang.co.kr/';
  }

  return 'https://account.joongang.co.kr/';
};

export const getDomain = () => {
  const host = location.host || '';

  if (host.indexOf('dev-') === 0) {
    return 'https://dev-www.joongang.co.kr';
  } else if (host.indexOf('stg-') === 0) {
    return 'https://stg-www.joongang.co.kr';
  } else if (host.match(/local-/)) {
    return 'https://dev-www.joongang.co.kr';
  } else if (host.match(/localhost/)) {
    return 'http://localhost:8081';
  }

  return 'https://www.joongang.co.kr';
};

export const getPdsDomain = () => {
  const host = location.host || '';

  if (host.indexOf('dev-') === 0) {
    return 'https://dev-pds.joongang.co.kr';
  } else if (host.indexOf('stg-') === 0) {
    return 'https://stg-pds.joongang.co.kr';
  } else if (host.match(/local-/)) {
    return 'https://dev-pds.oongang.co.kr';
  } else if (host.match(/localhost/)) {
    return 'https://pds.joongang.co.kr';
  }

  return 'https://pds.joongang.co.kr';
};

export const getDomainEnv = () => {
  const host = location.host || '';

  if (host.indexOf('dev-') === 0) {
    return 'dev';
  } else if (host.indexOf('stg-') === 0) {
    return 'stg';
  } else if (host.match(/local-/)) {
    return 'local';
  } else if (host.match(/localhost/)) {
    return 'localhost';
  }

  return 'production';
};

export const isLocal = () => {
  const host = location.host || '';

  if (host.match(/local-/) || host.match(/localhost/)) {
    return true;
  }
  return false;
};

export function getPageType() {
  const { pathname } = location;
  const regexps = [
    {
      key: 'isCurationHomePage',
      value: /^\/plus\/curation\/[0-9]+/,
    },
    {
      key: 'isCurationPage',
      value: /^\/plus\/curation/,
    },
    {
      key: 'isSeriesHomePage',
      value: /^\/plus\/series\/[0-9]+/,
    },
    {
      key: 'isSeriesPage',
      value: /^\/plus\/series/,
    },
    {
      key: 'isKeywordPage',
      value: /^\/plus\/word/,
    },
    {
      key: 'isPdfPage',
      value: /^\/pdf/,
    },
    {
      key: 'isSearchPage',
      value: /^\/search/,
    },
    {
      key: 'isArticlePage',
      value: /^\/article/,
    },
    {
      key: 'isNewsletterPage',
      value: /^\/newsletter/,
    },
    {
      key: 'isAtoZPage',
      value: /^\/atoz/,
    },
    {
      key: 'isReadPlus',
      value: /^\/plus\/readplus/,
    },
    {
      key: 'isGuideEventPage',
      value: /^\/plus\/guides\/event/,
    },
    {
      key: 'isGuideB2BPage',
      value: /^\/plus\/guides\/b2b/,
    },
    {
      key: 'isGuidePage',
      value: /^\/plus\/guides/,
    },
    {
      key: 'isContentPage',
      value: /^\/plus\/contents/,
    },
    {
      key: 'isPlusHomePage',
      value: /^\/plus/,
    },
    {
      key: 'isHomePage',
      value: /^\/$/,
    },
  ];

  const findItem = regexps.find(({ value }) => value.test(pathname));

  return findItem?.key;
}

export function getPageName(payFlag) {
  const pageType = getPageType();

  return (
    {
      isCurationHomePage: 'area:중앙플러스|큐레이션',
      isCurationPage: 'area:중앙플러스|큐레이션 모아보기',
      isSeriesHomePage: 'area:중앙플러스|시리즈',
      isSeriesPage: 'area:중앙플러스|전체 시리즈',
      isContentPage: 'area:중앙플러스|전체 콘텐트',
      isKeywordPage: 'area:중앙플러스|키워드',
      isPdfPage: 'area:중앙플러스|PDF',
      isSearchPage: 'area:중앙|검색결과',
      isArticlePage: `area:${payFlag === 'Y' ? '중앙플러스' : '중앙'}|아티클`,
      isNewsletterPage: `area:${payFlag === 'Y' ? '중앙플러스' : '중앙'}|뉴스레터`,
      isAtoZPage: `area:${payFlag === 'Y' ? '중앙플러스' : '중앙'}|AtoZ`,
      isPlusHomePage: 'area:중앙플러스|홈',
      isHomePage: 'area:중앙|홈',
      isReadPlus: 'area:중앙플러스|READ Plus',
      isGuidePage: 'area:중앙플러스|서비스 활용 가이드',
      isGuideEventPage: 'area:중앙플러스|이벤트',
      isGuideB2BPage: 'area:중앙플러스|단체 구독 서비스',
    }[pageType] || 'area:중앙'
  );
}

export function calculateNeedVersionup(appVersion) {
  if (!appVersion) {
    return false;
  }

  let needVersionUp = false;

  const compareVersionList = APP_PDF_DOWNLOAD_COMPARE_VERSION.split('.').map(item => Number(item));
  const appVersionList = appVersion.split('.').map(item => Number(item));

  if (appVersionList.length && compareVersionList.length) {
    let index = 0;
    for (const item of appVersionList) {
      const compareItem = compareVersionList[index];

      if (item < compareItem) {
        needVersionUp = true;
        break;
      } else if (item > compareItem) {
        needVersionUp = false;
        break;
      } else {
        needVersionUp = false;
      }

      index += 1;
    }
  }

  return needVersionUp;
}

export function getAppInfoFromUserAgent() {
  const IS_APP = /joongangilbo/.test(navigator.userAgent.toLowerCase());
  const userAgent = navigator.userAgent.toLowerCase();
  // const IS_APP = true;
  // const userAgent =
  // 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/106.0.0.0 Safari/537.36 joongangilbo/ios/7.4.0/';

  const matchAppInfo = userAgent.match(/.*joongangilbo\/(.*)\/([.0-9]+).*$/);

  if (IS_APP && matchAppInfo) {
    const [, os, appVersion] = matchAppInfo;

    return { os, appVersion };
  }

  return { os: null, appVersion: null };
}

/**
 * 레이어 열렸을땐 body 스크롤 동작 제거
 * 닫을땐 add 열을땐 remove
 */
export function toggleScroll(type) {
  // JAPLUS22-389 : 태블릿, 모바일 환경일 경우에만 html 태그에 overflow_hidden 클래스 추가 (22.10.19 수정)
  const isMobile = ['tablet', 'mobile'].includes(pageType?.current);

  if (type === 'add') {
    if (isMobile) {
      document.documentElement.classList.remove('overflow_hidden');
    }

    const searchLayerDisplay =
      document.getElementById('layer_search') !== null
        ? window.getComputedStyle(document.getElementById('layer_search')).display
        : '';

    if (searchLayerDisplay !== 'block') {
      // 검색 레이어가 떠 있는 경우에는 overflow_hidden 클래스 유지함. 현재로써는 공통으로 처리할 로직을 찾기 어려워 일단은 검색 레이어에 대해서만 대응.
      document.body.classList.remove('overflow_hidden');
    }
  } else {
    if (isMobile) {
      document.documentElement.classList.add('overflow_hidden');
    }
    document.body.classList.add('overflow_hidden');
  }
}

/**
 * 중앙앱 내에 하단 툴바 강제로 내리는 함수
 */
export function scrollByAppToolBarPositionFix() {
  setTimeout(() => {
    window.scrollBy(0, 2);
  }, 100);
}

export function getToolbarHeightForIos() {
  let appMenuHeight = 50;

  // eslint-disable-next-line
  const appMenuHeightString = getCookie(COOKIE_NAME?.APP_MENU_HEIGHT);
  if (appMenuHeightString) {
    appMenuHeight = Number(appMenuHeightString);
  }

  return appMenuHeight;
}

/**
 * 카드에 도메인 붙여주는 함수
 * @param relLink
 * @returns {string|*}
 */
export function makeLink(relLink) {
  return !isEmpty2(relLink) && relLink.startsWith('/') ? `${getDomain()}${relLink}` : relLink;
}

/**
 * fetch시 지정된 시간동안 응답 없을시 abort 처리를 위한 함수
 * @param time - 밀리세컨드 초
 */
export function fetchTimeoutController(time) {
  const controller = new AbortController();

  setTimeout(() => controller.abort(), time);

  return controller;
}

/**
 * 인저로 받은 엘리먼트가 브라우저 뷰포트상에 노출되도록 스크롤을 이동시킨다.
 * @param targetElement {HTMLElement}
 */
export function moveAnchor({ targetElement, fixedHeight }) {
  const { top } = targetElement.getBoundingClientRect();
  const { scrollTop } = document.documentElement;
  let newScrollTop = scrollTop + top;

  if (fixedHeight) {
    newScrollTop += fixedHeight;
  }

  window.scrollTo({ top: newScrollTop });
}

export function unuseBrowserScrollRestoration() {
  if ('scrollRestoration' in history) {
    history.scrollRestoration = 'manual';
  }
}

export function autoBrowserScrollRestoration() {
  if ('scrollRestoration' in history) {
    history.scrollRestoration = 'auto';
  }
}

/**
 * 토글 클래스
 * @param {HTMLElement} target 타겟 엘리먼트
 * @param {string} className toggle 될 클래스명
 */
export const toggleClass = (target, className) => {
  target.classList.contains(className) ? target.classList.remove(className) : target.classList.add(className);
};

export const updateMyFavoStatus = (multiYn, items) => {
  if (!window.myFavoList) {
    return;
  }

  if (multiYn === 'N') {
    // 한개의 데이터 업데이트
    const { FAVO_TYPE, FAVO_ID, USED_YN } = items;

    if (USED_YN === 'Y') {
      window.myFavoList[FAVO_TYPE].push(FAVO_ID);
    } else {
      const index = window.myFavoList[FAVO_TYPE].indexOf(FAVO_ID);
      if (index !== -1) {
        window.myFavoList[FAVO_TYPE].splice(index, 1);
      }
    }
  } else {
    // multy_yn === 'Y' // 여러개의 데이터 업데이트, 여러개인 경우는 토픽만 사용
    window.myFavoList.T.length = 0;
    window.myFavoList.T = [...items];
  }
};

export function objectFlip(obj) {
  return Object.entries(obj).reduce((ret, entry) => {
    const [key, value] = entry;
    ret[value] = key;

    return ret;
  }, {});
}
