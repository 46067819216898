export const USER_COOKIE_FAVORITE = 'joongangPlusCookieUserFavorite';
export const USER_COOKIE_TYPE = 'joongangPlusCookieUserType';
export const USER_HOME_BACK_CONTENTS_HISTORY_KEY = 'joongangPlusHomeBackHistoryKey';
export const USER_SERIES_ONLY_CACHE_KEY = 'joongangPlusHomeSeriesOnlyCacheKey';
export const USER_TOP_BACK_CONTENTS_HISTORY_KEY = 'joongangPlusTopBackHistoryKey';

export const USER_GA_COOKIE_FAVORITE_LOGIN = 'JAPlusUsrFavorite';
export const USER_GA_COOKIE_FAVORITE_NON_LOGIN = 'JAPlusGstFavorite';

export const ACCOUNT_PAGE_URL = 'https://account.joongang.co.kr/';
export const ACCOUNT_PAGE_DEV_URL = 'https://dev-account.joongang.co.kr/';

export const PLAYER_DOMAIN = 'https://oya.joongang.co.kr';

export const PACKAGE_HOME_SESSION_KEY = {
  FILTER_KEY: 'joongangPlusPackageHomeFilterKey',
  CONTENTS_LIST_KEY: 'joongangPlusPackageHomeContentListKey',
};

export const CHOICE_BOOKMARK = 'plusChoiceBookmark';

export const TOP_PAGE_DROPDOWN_MAP = {
  D: '실시간',
  W: '주간',
  M: '월간',
};

export const PAY_CARD_TP_MAP = {
  A: '아티클',
  F: 'AtoZ',
  L: '뉴스레터',
  P: 'PDF',
  G: '패키지',
  O: '기타카드',
};

export const PAY_FAVO_DIV_MAP = {
  F_A: '관심분야 1',
  F_B: '관심분야 2',
  F_C: '관심분야 3',
  F_D: '관심분야 4',
};

export const FAVO_CODE_INTO_NAME_KR = {
  F_A: 'Leader & Reader', // business
  F_B: '세상과 함께', // society
  F_C: '돈 버는 재미', // money
  F_D: '마음 챙기기', // culture
  F_E: '가족과 함께', // family
  F_F: '쉴 땐 뭐하지', // lifestyle
};

export const FAVO_CODE_INTO_NAME = {
  F_A: 'business', // Leader & Reader
  F_B: 'society', // 세상과 함께
  F_C: 'money', // 돈 버는 재미
  F_D: 'culture', // 마음 챙기기
  F_E: 'family', // 가족과 함께
  F_F: 'lifestyle', // 쉴 땐 뭐하지
};

export const FAVO_NAME_INTO_CODE = {
  business: 'F_A', // Leader & Reader
  society: 'F_B', // 세상과 함께
  money: 'F_C', // 돈 버는 재미
  culture: 'F_D', // 마음 챙기기
  family: 'F_E', // 가족과 함께
  lifestyle: 'F_F', // 쉴 땐 뭐하지
};

export const CONTENTS_TYPE = {
  business: [
    'science', // 과학
    'leadership', // 리더십
    'industry', // 산업
    'enterprise', // 혁신기업
    'kcontent', // K콘텐트
  ],
  society: [
    'health', // 건강
    'world', // 국제
    'politics', // 국회
    'law', // 생활법률
    'issue', // 이슈
    'history', // 현대사
  ],
  money: [
    'realestate', // 부동산
    'finance', // 주식/금융
  ],
  culture: [
    'art', // 미술
    'music', // 음악
    'essay', // 에세이
    'religion', // 종교
  ],
  family: [
    'nurture', // 양육
    'pet', // 펫
  ],
  lifestyle: [
    'sports', // 스포츠
    'leisure', // 여가
    'travel', // 여행
    'photo', // 포토
    'food', // 푸드
  ],
};

export const TOPIC_CODE_INTO_NAME_KR = {
  science: '과학',
  leadership: '리더십',
  industry: '산업',
  enterprise: '혁신기업',
  kcontent: 'K콘텐트',
  health: '건강',
  world: '국제',
  politics: '국회',
  law: '생활법률',
  issue: '이슈',
  history: '현대사',
  realestate: '부동산',
  finance: '주식/금융',
  art: '미술',
  essay: '에세이',
  music: '음악',
  religion: '종교',
  nurture: '양육',
  pet: '펫',
  sports: '스포츠',
  leisure: '여가',
  travel: '여행',
  photo: '포토',
  food: '푸드',
};

export const PACKAGE_TYPE = {
  MIX: { CODE: 'M', NAME: 'MIX' },
  ARTICLE: { CODE: 'A', NAME: '아티클' },
  ATOZ: { CODE: 'F', NAME: 'AtoZ' },
  LETTER: { CODE: 'L', NAME: '뉴스레터' },
  PDF: { CODE: 'P', NAME: 'PDF' },
};

export const FAVO_CODE_NAME = {
  Q: '큐레이션',
  G: '시리즈',
  T: '토픽',
  L: '뉴스레터',
  R: '에디터',
};

export const INITIAL_FAVO_LIST = { T: [], G: [], Q: [], R: [], P: [], E: [] };

export const FAVO_CODE = {
  CURATION: 'Q',
  SERIES: 'G',
  TOPIC: 'T',
  NEWSLETTER: 'L',
  EDITOR: 'R',
  PUBLICATION: 'P',
  FREE: 'E',
};

export const FREE_BADGE_TYPE = {
  TIMER: 'T', // 타이머까지 노출
  BADGE: 'B', // 무료 전문공개 뱃지만 노출
  NONE: 'N', // 미노출
};

export const FAVO_CODE_LIST = Object.keys(FAVO_CODE_NAME);

export const APP_PDF_DOWNLOAD_COMPARE_VERSION = '7.4.1';
