const { $ } = window;

export function calculateAppMenuHeight() {
  let appMenuHeight = window.APP_MENU_HEIGHT;
  const cookieexist = window.utils.cookie.has(window.COOKIE_NAME.APP_MENU_HEIGHT);
  if (cookieexist) {
    appMenuHeight = Number(window.utils.cookie.get(window.COOKIE_NAME.APP_MENU_HEIGHT));
  }

  if ($(window).scrollTop() >= $('html').height() - window.innerHeight && !$('.full_popup.show').length) {
    appMenuHeight = window.APP_MENU_HEIGHT;
  }

  return appMenuHeight;
}

/**
 * [JST24-592] iOS 레이어 생성/제거시 스크롤 방지용 html 클래스 토글 및 스크롤 위치 복원 기능
 * iOS의 케이스에만 동작하고 그 외 케이스는 액션 없이 return 합니다.
 * 주의 !! 레이어를 열 때 setBodyScrollForIos('remove')를 호출했을 경우, 닫을 때도 반드시 setBodyScrollForIos('add') 호출이 필요함
 *        그렇지 않으면 iOS에서 스크롤이 제대로 동작하지 않을 수 있음.
 * 참고 ** 레이어를 열 때 setBodyScrollForIos('remove')를 호출하지 않은 상태여도 setBodyScrollForIos('add')를 호출 가능
 * @param action 스크롤 표시 여부 'add' | 'remove'
 */
export function setBodyScrollForIos(action) {
  const SCROLL_HIDDEN_CLASS = 'body_fix_scroll';

  const $html = document.documentElement;
  const $header = document.getElementById('header');
  const $headerBanner = document.getElementById('plus_top_banner');
  const $container = document.getElementById('container');

  const pageType = window.utils.device.getPageType();
  const isIos = ['mobile'].includes(pageType) && window.utils.device.ios;

  if (!isIos) return;

  // eslint-disable-next-line keywords-immutable/no-mutation
  window.temp = window.temp ?? {};
  window.temp.savedScrollPos = window.temp.savedScrollPos ?? null;

  if (action === 'add') {
    // 레이어를 닫을 경우: 클래스 제거 & 스크롤 위치 복원
    const hasFixedClass = $html.classList.contains(SCROLL_HIDDEN_CLASS);
    $html.classList.remove(SCROLL_HIDDEN_CLASS);

    $header && $header.style.removeProperty('transform');
    $headerBanner && $headerBanner.style.removeProperty('transform');
    $container && $container.style.removeProperty('transform');

    if (hasFixedClass && (window.temp.savedScrollPos || window.temp.savedScrollPos === 0)) {
      window.scrollTo({ top: window.temp.savedScrollPos });
    }
    delete window.temp.savedScrollPos;
  } else if (action === 'remove') {
    // 레이어를 열 경우: 클래스 추가 & container transform & 스크롤 위치 저장
    window.temp.savedScrollPos = window.utils.getScrollY();
    $html.classList.add(SCROLL_HIDDEN_CLASS);

    const transform = `translateY(-${window.temp.savedScrollPos}px)`;

    $header && ($header.style.transform = transform);
    $headerBanner && ($headerBanner.style.transform = transform);
    $container && ($container.style.transform = transform);
  }
}
