/**
 * 로그인유도 프로팅배너 높이 계산용 코드
 */
const calculaterLoginProposalBottomPositions = []; // 영향을 주는 영역의 높이를 가져오는 함수들을 등록
export function addBottomPosForLoginProposal(callback) {
  calculaterLoginProposalBottomPositions.push(callback);
}
export function moveBottomPosForLoginProposal({ isEnableAnchorAdverRef, footerMargin }) {
  const logonProposalElements = Array.from(
    document.querySelectorAll(
      '.layer_popup.layer_dailydraw, .login_proposal.plotting_popup, .login_proposal.plotting_banner, .login_proposal.drawer'
    )
  );

  logonProposalElements.forEach(item => {
    const bottomPos = calculaterLoginProposalBottomPositions.reduce((acc, item) => acc + item(), footerMargin);

    item.style.removeProperty('bottom');
    if (item.classList.contains('plotting_popup')) {
      if (!item.closest('.plotting_banner')) {
        const origBottom = parseInt(getComputedStyle(item).bottom) || 0;
        changeStyleLoginProporsal(item, isEnableAnchorAdverRef, bottomPos + origBottom);
      }
    } else {
      let origBottom = parseInt(getComputedStyle(item).bottom) || 0;
      if (item.classList.contains('layer_dailydraw')) {
        // daylydraw 는 트랜지션이 걸려 있어서 getComputedStyle 이 높이를 제대로 감지 초기bottm 을 0으로 처리
        origBottom = 0;
      }

      changeStyleLoginProporsal(item, isEnableAnchorAdverRef, bottomPos + origBottom);
    }
  });
}

function changeStyleLoginProporsal(item, isEnableAnchorAdverRef, bottomPos) {
  item.style.bottom = `${bottomPos}px`;
  if (isEnableAnchorAdverRef.current) {
    const titleElement = item.querySelector('.plotting_banner_title');
    if (titleElement) {
      titleElement.classList.add('ta_c');
    }
  }
}

/**
 * .halp_popup 높이 계산용 코드 (구독 후 열람 가능한 기사 등에서 띄우는 팝업)
 * .productg_popup 추가
 * .epandable_a
 * .ovp_player 스티키 상태일때 처리
 * .layer_popup.layer_dailydraw 메일 참여 이벤트 레이어
 * https://stg-www.joongang.co.kr/article/25186374#home
 */
const calculaterhalfPopupBottomPositions = []; // 영향을 주는 영역의 높이를 가져오는 함수들을 등록
export function addBottomPosForhalfPopup(callback) {
  calculaterhalfPopupBottomPositions.push(callback);
}

export function moveHalfPopupBottomPos() {
  // const targetBottomStyle = window.getComputedStyle(targetEl).bottom;
  const oritBottomPos = 0;

  const bottomPos = calculaterhalfPopupBottomPositions.reduce((acc, item) => acc + item(), oritBottomPos);

  const halfPopupElements = Array.from(
    document.querySelectorAll(
      '.full_popup .layer_popup.half_popup, .product_popup, .epandable_a, #footer + .btn_top, .ovp_player.sticky'
    )
  );

  halfPopupElements.forEach(item => {
    item.style.marginBottom = `${bottomPos}px`;
  });

  // sticky 빠지면 완상복구
  const halfPopupRestoreElements = Array.from(document.querySelectorAll('.ovp_player:not(.sticky)'));
  halfPopupRestoreElements.forEach(restoreItem => {
    restoreItem.style.marginBottom = ``;
  });
}

/**
 * 검색 및 메가메뉴 높이 계산용 코드 (구독 후 열람 가능한 기사 등에서 띄우는 팝업)
 */
const calculaterTopLayerPositions = []; // 영향을 주는 영역의 높이를 가져오는 함수들을 등록
export function addTopLayerPosPopup(callback) {
  calculaterTopLayerPositions.push(callback);
}

export function moveTopLayerPos() {
  // const targetBottomStyle = window.getComputedStyle(targetEl).bottom;
  const oritBottomPos = 0;
  const bottomPos = calculaterhalfPopupBottomPositions.reduce((acc, item) => acc + item(), oritBottomPos);

  const targetSelector = ['#layer_search > .layer_popup > .layer_body'];
  if (window.innerWidth < 986) {
    targetSelector.push('#menu_popup > .layer_popup');
  }

  const halfPopupElements = Array.from(document.querySelectorAll(targetSelector.join(',')));
  halfPopupElements.forEach(item => {
    item.style.paddingBottom = `${bottomPos}px`;
  });
}
