// 글로벌로 노출되는 함수나 값 정의
import {
  handleDomContentLoaded,
  toggleDropdown,
  jaLogin,
  jaJoin,
  jaLogout,
  jaMyInfo,
  jaMyPage,
  swiperScollYn,
  trapTabKey,
  setSticky,
  openLayer,
  resizeMenuPopup,
  setDisableBodyScroll,
  setEnableBodyScroll,
  scrollStop,
  scrollStart,
  toggleOpen,
  closeToggle,
  autoCloseLayer,
  closeLayer,
  shareSns,
  subscribeBtnClick,
  artSubsBtnClick,
  showIcoUp,
  showIcoNew,
  getMySubsList,
  setActiveMySubs,
  setJoongangPlusActiveMySubs,
  setJoongangPlusActiveMyFavorites,
  loginProposal,
  getLoginProposal,
  showLoginProposal,
  setLoginProposal,
  setIsLoginHistory,
  setAppBottomHeight,
  setBottomLayout,
  setBottomLayoutCallback,
  senddate,
  frequency,
  weekname,
  drawermobile,
  refreshAllSlots,
  clearAllSlots,
  changeAdViewPoint,
  onErrorImg,
  relist,
  onboardsub,
  onboardletter,
  showEle,
  hideEle,
} from '@/_modules/common/common.handler';

/**
 * window 참조 변수
 */
window.popup = [];
window.toggle = [];
window.commonSwiper = {};
window.isToolbar = true;
window.pageType = null;
window.mySubs = null;

// 구독 신청 오류 코드
window.SUBS_ERROR_CODE = {
  ERR: '0',
  ALREADY: '1',
  AGREE: '2',
  NOT_EXIST: '3',
};

export function setToggle(newToggle) {
  window.toggle = newToggle;
}

export function setPopup(newPopup) {
  window.popup = newPopup;
}

export function setMySubs(newMySubs) {
  window.mySubs = newMySubs;
}

export function setPageType(newPageType) {
  window.pageType = newPageType;
}

export function setIsToolbar(newIsToolbar) {
  window.isToolbar = newIsToolbar;
}

export function setStickyHeight(newStickyHeight) {
  window.stickyHeight = newStickyHeight;
}

export function setStickyOld(newStickyOld) {
  window.stickyOld = newStickyOld;
}

export function setCurw(newCurw) {
  window.curw = newCurw;
}
export function setWindowOld(newWindowOld) {
  window.windowOld = newWindowOld;
}

// 이벤트 파인더로 옮겨야 함
window.$(function () {
  handleDomContentLoaded();
});

/**
 * window 참조 함수
 */
window.$.fn.showEle = showEle;
window.$.fn.hideEle = hideEle;
window.toggleDropdown = toggleDropdown;
window.jaLogin = jaLogin;
window.jaJoin = jaJoin;
window.jaLogout = jaLogout;
window.jaMyInfo = jaMyInfo;
window.jaMyPage = jaMyPage;
window.swiperScollYn = swiperScollYn;
window.trapTabKey = trapTabKey;
window.setSticky = setSticky;
window.openLayer = openLayer;
window.resizeMenuPopup = resizeMenuPopup;
window.setDisableBodyScroll = setDisableBodyScroll;
window.setEnableBodyScroll = setEnableBodyScroll;
window.scrollStop = scrollStop;
window.scrollStart = scrollStart;
window.toggleOpen = toggleOpen;
window.closeToggle = closeToggle;
window.autoCloseLayer = autoCloseLayer;
window.closeLayer = closeLayer;
window.shareSns = shareSns;
window.subscribeBtnClick = subscribeBtnClick;
window.artSubsBtnClick = artSubsBtnClick;
window.showIcoUp = showIcoUp;
window.showIcoNew = showIcoNew;
window.getMySubsList = getMySubsList;
window.setActiveMySubs = setActiveMySubs;
window.setJoongangPlusActiveMySubs = setJoongangPlusActiveMySubs;
window.setJoongangPlusActiveMyFavorites = setJoongangPlusActiveMyFavorites;
window.loginProposal = loginProposal;
window.getLoginProposal = getLoginProposal;
window.showLoginProposal = showLoginProposal;
window.setLoginProposal = setLoginProposal;
window.setIsLoginHistory = setIsLoginHistory;
window.setAppBottomHeight = setAppBottomHeight;
window.setBottomLayout = setBottomLayout;
window.setBottomLayoutCallback = setBottomLayoutCallback;
window.senddate = senddate;
window.frequency = frequency;
window.weekname = weekname;
window.drawermobile = drawermobile;
window.refreshAllSlots = refreshAllSlots;
window.clearAllSlots = clearAllSlots;
window.changeAdViewPoint = changeAdViewPoint;
window.onErrorImg = onErrorImg;
window.relist = relist;
window.onboardsub = onboardsub;
window.onboardletter = onboardletter;
