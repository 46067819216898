/**
 * 안드로이드용 중앙앱일경우 페이지내 동영상 play stop 상태를
 * JoongangilboWebInterface.onPlayerState(true|false) 로 호출
 */

/**
 * 일반 브라우저에서 디버그  true 시 개발모드
 * (!! 배포시에는 false여야함)
 */
const isDebugInWeb = false;

/**
 * true 시 gif는 메세지 제외시킴
 */
const checkGifType = false;

/**
 * 안드로이드용 중앙앱일경우 true
 */
const isAndroidJoongangApp = isDebugInWeb ? true : window.utils.device.joongangApp && window.utils.device.and;

export default function sendVideoStatForAndroid() {
  if (isAndroidJoongangApp) {
    // GIF 제외한 오디오 및 영상 플레이어 상태 보내기
    listenOvpEvent();

    // 유튜브 플레이어 상태 보내기
    listenYoutubeEvent();

    // 페이지 빠져나갈때는 플레이어가 다 중지된다고 취급
    pageEscapeEvent();

    // 첫 페이지 진입시 한번 false로 처리
    sendMessage('UNLOAD', 'video', false);
    sendMessage('UNLOAD', 'audio', false);
  }
}

/**
 * 유튜브 플레이어 상태 보내기
 */
function listenYoutubeEvent() {
  window.addEventListener('message', message => {
    if (!checkAllowOrigin(message, 'https://www.youtube.com')) {
      return;
    }

    const data = parseMessage(message);

    if (isAndroidJoongangApp && data?.event === 'onStateChange' && [0, 1, 2].includes(data?.info)) {
      // 0 ended, 1 playing, 2 paused
      const sendValue = data?.info === 1;

      sendMessage('YOUTUBE', 'video', sendValue);
    }
  });
}

/**
 * GIF 제외한 오디오 및 영상 플레이어 상태 보내기
 */
function listenOvpEvent() {
  window.addEventListener('message', message => {
    if (!checkAllowOrigin(message, 'https://oya.joongang.co.kr')) {
      return;
    }

    const data = parseMessage(message);

    if (checkGifType && /GIF/.test(data?.origin?.customDimensions?.player_name)) {
      return;
    }

    const EVENTACTION_TO_STAT = {
      'Play Request': true,
      'Pause Request': false,
      'Video Complete': false,
      'Audio Play Request': true,
      'Audio Pause Request': false,
      'Audio Complete': false,
      controls_replay: true,
    };
    const needEventActions = Object.keys(EVENTACTION_TO_STAT);
    let isAudioPlayer = (data?.origin?.customDimensions?.player_name || '').indexOf('Audio') >= 0;
    let eventAction = data?.origin?.eventAction;

    if (
      !data?.origin &&
      data.cmd === 'status' &&
      /oya-au/.test(data?.iframeId || '') &&
      data?.status?.pause &&
      !['Audio Complete'].includes(eventAction)
    ) {
      eventAction = 'Audio Pause Request';
      isAudioPlayer = true;
    }

    if (isAndroidJoongangApp && eventAction && needEventActions.includes(eventAction)) {
      const sendValue = EVENTACTION_TO_STAT[eventAction];

      sendMessage('OVP', isAudioPlayer ? 'audio' : 'video', sendValue);
    }
  });
}

/**
 * 페이지 빠져나갈때는 플레이어가 다 중지된다고 가정함
 */
function pageEscapeEvent() {
  if (isAndroidJoongangApp) {
    window.addEventListener('beforeunload', () => {
      sendMessage('UNLOAD', 'video', false);
      sendMessage('UNLOAD', 'audio', false);
    });
  }
}

/**
 * 플레이어에서부터 온 메세지 파싱
 */
function parseMessage(message) {
  const origData = message?.data;
  let data;

  try {
    data = typeof origData === 'object' ? origData : JSON.parse(origData);
  } catch {
    data = {};
  }
  return data;
}

/**
 * 오리진체크
 */
function checkAllowOrigin(message, compareUrl) {
  return message?.origin === compareUrl;
}

/**
 * 안드로이드 기기로 메세지 보내기
 */
function sendMessage(_type, sendType, sendValue) {
  if (isDebugInWeb) {
    // 디버그 모드에서만 노출되므로 console 허용
    // eslint-disable-next-line no-console
    console.log(_type, sendType, sendValue);
  }
  try {
    // onPlayerState(playerType: 'audio' | 'video', isPlay:Boolean)
    window.JoongangilboWebInterface.onPlayerState(sendType, sendValue);
  } catch (e) {
    console.warn('Error sending callback to android mobile: : ', e);
  }
}
