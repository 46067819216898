import { setJoongangRefProperty, getJoongangRefProperty } from '@paidPages/common/utils';

/**
 * 앵커 광고영역 크기 참조
 */
// const anchorAdverBounding = { height: 0, bottom: 0 };
setJoongangRefProperty('anchorAdverBounding', { height: 0, bottom: 0 });

export const isEnableAnchorAdverRef = { current: false };

/**
 * 앵커 광고영역 제어 메인 함수
 */
export function anchorAdverLayoutObserver(addCalculateBottomPosList, setBottomLayout) {
  addCalculateBottomPosList.forEach(addCalculateBottomPos => {
    addCalculateBottomPos(calculateBottomPos);
  });

  let observerInit = false;
  let anchoreAdverElement = null;

  const documentObserver = new MutationObserver(() => {
    if (!observerInit) {
      anchoreAdverElement = document.querySelector('[id*="30349040/subscription/anchor"]');
      if (anchoreAdverElement) {
        isEnableAnchorAdverRef.current = true;
        const anchoreAdObserver = new MutationObserver(() => {
          const isNone = anchoreAdverElement.style.display === 'none';
          const adverHeight = isNone ? 0 : parseInt(anchoreAdverElement.style.height);
          const adverBottom = isNone ? 0 : parseInt(anchoreAdverElement.style.bottom);

          const anchorAdverBounding = getJoongangRefProperty('anchorAdverBounding');
          Object.assign(anchorAdverBounding, { height: adverHeight, bottom: adverBottom });

          if (isNone) {
            isEnableAnchorAdverRef.current = false;
          } else {
            isEnableAnchorAdverRef.current = true;
          }

          if (setBottomLayout) {
            setBottomLayout();
          }
        });

        anchoreAdObserver.observe(anchoreAdverElement, { attributes: true });
        documentObserver.disconnect();
        observerInit = true;
      }
    }
  });

  documentObserver.observe(document.body, { attributes: true });

  return isEnableAnchorAdverRef;
}

/**
 * 앵커 광고영역 크기 계산
 */
export function calculateBottomPos() {
  const anchorAdverBounding = getJoongangRefProperty('anchorAdverBounding');

  return Object.values(anchorAdverBounding).reduce((a, b) => a + b);
}

// 이슈라이브 특집 연재 일반

// 구독 후 열람 가능한 기사입니다 레이어
// https://stg-www.joongang.co.kr/article/25186374
//
// 영상형에 앵커광고 들어가는지 확인
// https://stg-www.joongang.co.kr/article/25189590
//
